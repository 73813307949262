import moment from "moment";
import Swal from "sweetalert2";

export function changeCapitalize(string) {
    let lower = "";
    let each_capital = "";

    // console.log(string);
    if (string !== undefined) {
        if (string === string.toUpperCase()) {
            lower = string.toLowerCase();
            each_capital = lower.replace(/\b[a-z]/g, function (m) {
                return m.toUpperCase();
            });
        } else {
            each_capital = string.replace(/\b[a-z]/g, function (m) {
                return m.toUpperCase();
            });
        }

        return each_capital;
    }
}

export function imgToFile(data) {
    var base64data = data.replace("data:image/png;base64,", "");
    var bs = atob(base64data);
    var buffer = new ArrayBuffer(bs.length);
    var ba = new Uint8Array(buffer);
    for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
    }
    var file = new Blob([ba], { type: "image/png" });
    return file;
}

export function namaSektor(data) {
    let name = "";
    switch (data) {
        case "e0":
            name = "Konsumtif";
            break;
        case "e2":
            name = "Pertanian,Perburuan dan Kehutanan";
            break;
        case "e5":
            name = "Perikanan";
            break;
        case "e6":
            name = "Pertambangan";
            break;
        case "e12":
            name = "Industri Pengolahan";
            break;
        case "e36":
            name = "Listrik, Gas, dan Air";
            break;
        case "e39":
            name = "Konstruksi";
            break;
        case "e40":
            name = "Perdagangan Besar dan Eceran";
            break;
        case "e46":
            name = "Penyediaan Akomodasi dan Penyediaan Makan Minum";
            break;
        case "e47":
            name = "Transportasi, Pergudangan, dan Komunikasi";
            break;
        case "e52":
            name = "Perantara Keuangan";
            break;
        case "e56":
            name = "Real Estate, Usaha Persewaan, dan Jasa Perusahaan";
            break;
        case "e62":
            name = "Administrasi Pemerintahan, Pertahanan dan Jaminan Sosial Wajib";
            break;
        case "e63":
            name = "Pendidikan";
            break;
        case "e64":
            name = "Sosial";
            break;
        case "e67":
            name = "Kegiatan Organisasi Yang Tidak Diklasifikasi Ditempat Lain";
            break;
        case "e70":
            name = "Jasa Perorangan yang Melayani Rumah Tangga";
            break;
        case "e71":
            name = "Badan Internasional dan Badan Ekstra Internasional Lainnya";
            break;
        case "e72":
            name = "Kegiatan yang belum jelas Batasannya";
            break;
        case "e73":
            name = "Sektor Ekonomi Bukan Lapangan Usaha";
            break;
        case "e74":
            name = "Rumah Tangga";
            break;
        case "e75":
            name = "Bukan Lapangan Usaha Lainnya";
            break;
        default:
            name = "Lain lain";
            break;
    }
    return name;
}

export function colorGrade(data) {
    if (data === "A") {
        return "#60D155";
    } else if (data === "B") {
        return "#FF7A00";
    } else if (data === "C") {
        return "#FF5B4C";
    } else if (data === "D") {
        return "#D82727";
    } else {
        return "#A9232D";
    }
}

export function sukuBunga(tipe, jenis, produktif, konsumtif, micro, spread_bunga, front) {
    let sukubunga = "";
    let spreadBunga;
    if (spread_bunga === undefined) {
        spreadBunga = 0;
    } else {
        spreadBunga = spread_bunga;
    }

    if (front === true) {
        if (tipe === "Micro Financing" && jenis === "Micro Bullet Reguler") {
            sukubunga = Math.floor(micro * 100) + "%";
        } else if (tipe === "Employee Loan") {
            sukubunga = konsumtif * 100 + "%";
        } else if (tipe === "Micro Financing" && jenis === "Installment") {
            sukubunga = Math.round((produktif - spreadBunga) * 100) + "%";
        } else {
            sukubunga = Math.floor((produktif - spreadBunga) * 100) + "%";
        }
    } else {
        if (tipe === "Micro Financing" && jenis === "Installment") {
            sukubunga = "Flat " + Math.round((produktif - spreadBunga) * 100) + "% /bulan";
        } else if (tipe === "Micro Financing" && jenis === "Micro Bullet Reguler") {
            sukubunga = "Efektif " + Math.floor(micro * 100) + "% /tahun";
        } else if (tipe === "Employee Loan") {
            sukubunga = "Flat " + konsumtif * 100 + "% /bulan";
        } else {
            sukubunga = "Efektif " + Math.floor((produktif - spreadBunga) * 100) + "% /tahun";
        }
    }

    return sukubunga;
}

export function colorStatistikSektor(data) {
    let colorStatistik = "";
    switch (data) {
        case "e0":
            colorStatistik = "#1B998B";
            break;
        case "e2":
            colorStatistik = "#2D3047";
            break;
        case "e5":
            colorStatistik = "#6cb2eb";
            break;
        case "e6":
            colorStatistik = "#734a12";
            break;
        case "e12":
            colorStatistik = "#465945";
            break;
        case "e36":
            colorStatistik = "#cd5b45";
            break;
        case "e39":
            colorStatistik = "#ace1af";
            break;
        case "e40":
            colorStatistik = "#918151";
            break;
        case "e46":
            colorStatistik = "#b22222";
            break;
        case "e47":
            colorStatistik = "#9acd32";
            break;
        case "e52":
            colorStatistik = "#560319";
            break;
        case "e56":
            colorStatistik = "#bc8f8f";
            break;
        case "e63":
            colorStatistik = "#b8860b";
            break;
        case "e64":
            colorStatistik = "#f0dc82";
            break;
        case "e74":
            colorStatistik = "#f0dc82";
            break;
        case "e73":
            colorStatistik = "#fcd553";
            break;
        default:
            colorStatistik = "#8E8E93";
            break;
    }
    return colorStatistik;
}

export function CheckDigisignData(data, validate) {
    if (data === validate) {
        Swal.fire({
            title: "Mengganti Data",
            html: "Data Anda tidak dapat di verifikasi oleh Dukcapil karena terdapat beberapa data tidak sesuai. Mohon untuk mengganti data dengan benar sesuai arahan dari Admin Indofund.",
            icon: "info",
            button: "ok"
        });
    }
}
//if else using opera mini /mozila firefox

export function InfoBrowser() {
    if (window.innerWidth <= 800 && typeof InstallTrigger !== "undefined") {
        Swal.fire({
            html: "Beberapa fungsi mungkin tidak akan berjalan. Untuk dapat menggunakan fitur dengan baik gunakan peramban <b>Google Chrome atau Safari</b> ",
            icon: "warning",
            button: "ok"
        });
    }
}

export function colorCurrency(data, status) {
    if (
        data === "Danai proyek" ||
        (data === "Tarik saldo" && status !== "Batal") ||
        data === "Pembayaran cicilan" ||
        data === "Pembayaran denda" ||
        data === "Asuransi" ||
        data === "Auto withdraw" ||
        data === "PPH 23" ||
        data === "Koreksi"
    ) {
        return "mb-0 text-danger";
    } else {
        return "mb-0 text-success";
    }
}

export function ballanceCurrency(data, status) {
    if (
        data === "Danai proyek" ||
        (data === "Tarik saldo" && status !== "Batal") ||
        data === "Pembayaran cicilan" ||
        data === "Pembayaran denda" ||
        data === "Asuransi" ||
        data === "Auto withdraw" ||
        data === "PPH 23" ||
        data === "Koreksi"
    ) {
        return "- Rp ";
    } else {
        return "Rp ";
    }
}

export function progressBarProject(data) {
    if (data === 100) {
        return data;
    } else {
        var stringNum = data.toString();
        return stringNum.substring(0, 4);
    }
}

export function conversStatistic(data) {
    const conversation =
        Math.abs(Number(data)) >= 1.0e9
            ? (Math.abs(Number(data)) / 1.0e9).toFixed(2) + " B"
            : // Six Zeroes for Millions
            Math.abs(Number(data)) >= 1.0e6
                ? (Math.abs(Number(data)) / 1.0e6).toFixed(2) + " M"
                : // Three Zeroes for Thousands
                Math.abs(Number(data)) >= 1.0e3
                    ? (Math.abs(Number(data)) / 1.0e3).toFixed(2) + " K"
                    : Math.abs(Number(data));

    return conversation;
}

export function newInsuranceCalcule(tipePinjaman, jangkaWaktu, nilaiPenawaranPinjaman) {
    let asuransi;
    if (!nilaiPenawaranPinjaman) {
        asuransi = 0;
    } else {
        // if (jangkaWaktu < 3) {

        //presentase 1% asuransi
        asuransi = (1 * nilaiPenawaranPinjaman) / 100;
        // } else {
        //   asuransi = (jangkaWaktu * 0.5 * nilaiPenawaranPinjaman) / 100

        // }
    }
    return asuransi;
}
// export function asuransi(tipePinjaman, jangkaWaktu, nilaiPenawaranPinjaman) {
//   let asuransi;
//   // jangak_waktu< 3
//   // jangka_awaktu * 0.5 * nilaiPenawaranPinjaman / 100
//   if (!nilaiPenawaranPinjaman) {
//     asuransi = 0;
//   } else {
//     if (
//       tipePinjaman === "Invoice Financing" ||
//       tipePinjaman === "Project Financing" ||
//       tipePinjaman === "Working Capital Financing"
//     ) {
//       if (jangkaWaktu === 1) {
//         asuransi = (nilaiPenawaranPinjaman * 0.65) / 100;
//       } else if (jangkaWaktu === 2) {
//         asuransi = (nilaiPenawaranPinjaman * 1.0) / 100;
//       } else if (jangkaWaktu >= 3 && jangkaWaktu <= 5) {
//         asuransi = (nilaiPenawaranPinjaman * 1.3) / 100;
//       } else if (jangkaWaktu >= 6 && jangkaWaktu <= 8) {
//         asuransi = (nilaiPenawaranPinjaman * 1.7) / 100;
//       } else if (jangkaWaktu >= 9 && jangkaWaktu <= 11) {
//         asuransi = (nilaiPenawaranPinjaman * 2.4) / 100;
//       } else if (jangkaWaktu >= 12) {
//         asuransi = (nilaiPenawaranPinjaman * 2.7) / 100;
//       }
//     } else if (tipePinjaman === "Micro Financing") {
//       if (jangkaWaktu === 1) {
//         asuransi = (nilaiPenawaranPinjaman * 0.7) / 100;
//       } else if (jangkaWaktu === 2) {
//         asuransi = (nilaiPenawaranPinjaman * 1.1) / 100;
//       } else if (jangkaWaktu >= 3 && jangkaWaktu <= 5) {
//         asuransi = (nilaiPenawaranPinjaman * 1.4) / 100;
//       } else if (jangkaWaktu >= 6 && jangkaWaktu <= 8) {
//         asuransi = (nilaiPenawaranPinjaman * 1.8) / 100;
//       } else if (jangkaWaktu >= 9 && jangkaWaktu <= 11) {
//         asuransi = (nilaiPenawaranPinjaman * 2.5) / 100;
//       } else if (jangkaWaktu >= 12) {
//         asuransi = (nilaiPenawaranPinjaman * 2.8) / 100;
//       }
//     } else if (tipePinjaman === "Employee Loan" || tipePinjaman === "Koperasi") {
//       if (jangkaWaktu === 1) {
//         asuransi = (nilaiPenawaranPinjaman * 0.75) / 100;
//       } else if (jangkaWaktu === 2) {
//         asuransi = (nilaiPenawaranPinjaman * 1.2) / 100;
//       } else if (jangkaWaktu >= 3 && jangkaWaktu <= 5) {
//         asuransi = (nilaiPenawaranPinjaman * 1.5) / 100;
//       } else if (jangkaWaktu >= 6 && jangkaWaktu <= 8) {
//         asuransi = (nilaiPenawaranPinjaman * 1.9) / 100;
//       } else if (jangkaWaktu >= 9 && jangkaWaktu <= 11) {
//         asuransi = (nilaiPenawaranPinjaman * 2.6) / 100;
//       } else if (jangkaWaktu >= 12) {
//         asuransi = (nilaiPenawaranPinjaman * 2.9) / 100;
//       }
//     }
//   }
//   return asuransi;
// }

export function estimasiBunga(jenisPinjaman, pokok, sukuBunga, sukuBungaEmployee, sukuBungaMicro, tenor, spreadBunga, nominal) {
    let spread = spreadBunga ? spreadBunga : 0;
    let bungaLender = sukuBunga - spread;
    let estimasiBunga;
    if (!nominal) {
        estimasiBunga = 0;
    } else {
        if (jenisPinjaman === "Installment") {
            var angsuran = (pokok * (bungaLender / 12)) / (1 - 1 / Math.pow(1 + bungaLender / 12, tenor));
            estimasiBunga = Math.floor((nominal / pokok) * (angsuran * tenor) - nominal);
        } else if (jenisPinjaman === "Employee Loan Installment") {
            estimasiBunga = ((nominal * sukuBungaEmployee) / 12) * tenor;
        } else if (jenisPinjaman === "Micro Bullet Reguler") {
            estimasiBunga = Math.floor(nominal * sukuBungaMicro * (tenor / 360));
        } else {
            estimasiBunga = ((nominal * bungaLender) / 12) * tenor;
        }
    }

    return Math.floor(estimasiBunga);
}

export function satuanJWP(data) {
    if (data === 1) {
        return "Hari";
    } else if (data === 3) {
        return "Bulan";
    }
}

export function bilanganNominal(data) {
    const conversation =
        Math.abs(Number(data)) >= 1.0e9
            ? Math.abs(Number(data)) / 1.0e9 + " B"
            : // Six Zeroes for Millions
            Math.abs(Number(data)) >= 1.0e6
                ? Math.abs(Number(data)) / 1.0e6 + " jt"
                : // Three Zeroes for Thousands
                Math.abs(Number(data)) >= 1.0e3
                    ? Math.abs(Number(data)) / 1.0e3 + " ribu"
                    : Math.abs(Number(data));
    return conversation;
}

export function arrayItemProject(data) {
    const arr = [];
    data.map((project) => {
        // console.log(project)
        if (project.nilai_total_peminjam < project.nilai_pengajuan_pinjaman && project.status_proyek === "Menggalang dana") {
            arr.unshift(project);
        } else if (
            project.nilai_total_peminjam < project.nilai_pengajuan_pinjaman &&
            (project.status_proyek === "Pembayaran cicilan" || project.status_proyek === "Terdanai sebagian")
        ) {
            arr.push(project);
        } else if (project.nilai_total_peminjam === project.nilai_pengajuan_pinjaman) {
            arr.push(project);
        }
    });

    return arr;
}

export function convertFileSize(data) {
    if (data === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(data) / Math.log(k));

    return parseFloat((data / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export function isMobileCheck() {
    return window.innerWidth <= 702;
}

export function getFullTime(data) {
    return moment(data).format("DD-MMM-YYYY hh:mm A").replace("sore", "PM").replace("pagi", "AM");
}
