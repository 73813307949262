import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { estimasiBunga } from "../../../library/helper/helper";
import { Collapse, Table } from "reactstrap";

import moment from "moment";
import "moment/locale/id";

class DetailPendanaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showPengembalian: false,
            showAsuransi: false
        };
        this.toggle = this.toggle.bind(this);
        this.togglePengembalian = this.togglePengembalian.bind(this);
        this.toggleAsuransi = this.toggleAsuransi.bind(this);
    }
    toggle() {
        this.setState({ show: !this.state.show });
    }
    togglePengembalian() {
        this.setState({ showPengembalian: !this.state.showPengembalian });
    }
    toggleAsuransi() {
        this.setState({ showAsuransi: !this.state.showAsuransi });
    }
    arrow(data) {
        if (data === false) {
            return <i className="fa fa-chevron-up fa-sm pl-2"></i>;
        } else {
            return <i className="fa fa-chevron-down fa-sm pl-2"></i>;
        }
    }

    listPendanaan = (data) => {
        if (data.akumulasi_lend === undefined) {
        } else {
            let count = data.akumulasi_lend.length;
            let total_pendanaan = data.total_pendanaan;
            let bunga = estimasiBunga(
                data.id_pinjaman.jenis_pinjaman,
                data.id_pinjaman.nilai_pengajuan_pinjaman,
                data.id_pinjaman.suku_bunga_pinjaman,
                data.id_pinjaman.suku_bunga_konsumtif,
                data.id_pinjaman.suku_bunga_micro,
                data.id_pinjaman.jangka_waktu_pinjaman,
                data.id_pinjaman.spread_bunga,
                total_pendanaan
            );
            let total_pengembalian = total_pendanaan + bunga;
            if (count > 1) {
                return (
                    <>
                        <div>
                            <div className="row font-weight-bold click-detail">
                                <div className="col-6">
                                    <p onClick={this.toggle}>Total Pendanaan {this.arrow(this.state.show)}</p>
                                </div>
                                <div className="col-6">
                                    <NumberFormat
                                        value={total_pendanaan}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"Rp "}
                                        renderText={(value) => <span className="float-right">{value}</span>}
                                    />
                                </div>
                            </div>
                            <Collapse isOpen={this.state.show}>
                                <div className="card-table mb-4 ">
                                    <Table borderless responsive className="table-pendanaan-custom">
                                        <thead>
                                            <tr>
                                                <th>Tanggal Pendanaan</th>
                                                <th className="float-md-right float-left">Nilai Pendanaan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.akumulasi_lend.map((val) => {
                                                const tanggal = moment(new Date(val.tgl_penawaran_pemberian_pinjaman)).format("DD/MM/YYYY");
                                                return (
                                                    <tr key={val._id}>
                                                        <td>{tanggal}</td>
                                                        <td>
                                                            <NumberFormat
                                                                value={val.nilai_penawaran_disetujui}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                prefix={"Rp "}
                                                                renderText={(value) => <span className="float-md-right float-left">{value}</span>}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Collapse>
                        </div>
                        <div className="row font-weight-bold">
                            <div className="col-6">
                                <p>{data.id_pinjaman.status_proyek !== "Proyek selesai" ? "Estimasi Bunga" : "Bunga"}</p>
                            </div>
                            <div className="col-6">
                                <NumberFormat
                                    value={bunga}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"Rp "}
                                    renderText={(value) => <span className="float-md-right float-left">{value}</span>}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row font-weight-bold click-detail">
                            <div className="col-6">
                                <p>Total pengembalian</p>
                            </div>
                            <div className="col-6">
                                <NumberFormat
                                    value={total_pengembalian}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"Rp "}
                                    renderText={(value) => <span className="float-md-right float-left">{value}</span>}
                                />
                            </div>
                        </div>
                        <p className="info">
                            *Perhitungan bunga bersifat tentatif, bergantung pada jumlah hari efektif antara tanggal pencairan pinjaman dan tanggal
                            pembayaran pinjaman
                        </p>
                    </>
                );
            } else {
                return (
                    <>
                        <div className="row font-weight-bold">
                            <div className="col-6">
                                <p>Tanggal Pendanaan</p>
                            </div>
                            <div className="col-6">
                                {data.akumulasi_lend.map((val) => {
                                    const tanggal = moment(new Date(val.tgl_penawaran_pemberian_pinjaman)).format("DD/MM/YYYY");
                                    return (
                                        <span className="float-right" key={val._id}>
                                            {tanggal}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="row font-weight-bold">
                            <div className="col-6">
                                <p>Nominal Pendanaan</p>
                            </div>
                            <div className="col-6">
                                <span>
                                    <NumberFormat
                                        value={total_pendanaan}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"Rp "}
                                        renderText={(value) => <span className="float-md-right float-left">{value}</span>}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="row font-weight-bold">
                            <div className="col-6">
                                <p>{data.id_pinjaman.status_proyek !== "Proyek selesai" ? "Estimasi Bunga" : "Bunga"}</p>
                            </div>
                            <div className="col-6">
                                <span>
                                    <NumberFormat
                                        value={bunga}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"Rp "}
                                        renderText={(value) => <span className="float-md-right float-right">{value}</span>}
                                    />
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div className="row font-weight-bold click-detail">
                            <div className="col-6">
                                <p>Total pengembalian</p>
                            </div>
                            <div className="col-6">
                                <NumberFormat
                                    value={total_pengembalian}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"Rp "}
                                    renderText={(value) => <span className="float-md-right float-left">{value}</span>}
                                />
                            </div>
                        </div>
                        <p className="info">
                            *Perhitungan bunga bersifat tentatif, bergantung pada jumlah hari efektif antara tanggal pencairan pinjaman dan tanggal
                            pembayaran pinjaman
                        </p>
                    </>
                );
            }
        }
    };

    // listAsuransi = (data) => {
    //     let arr = [];
    //     let current_status;
    //     let total_asuransi = 0;
    //     let onePremi = 0;
    //     let no_polis;

    //     if (data.akumulasi_lend === undefined) {
    //     } else {
    //         let count = data.akumulasi_lend.length;

    //         //masuk ke dalam array baru yg asuransi status BUKAN tidak digunakan
    //         data.akumulasi_lend.map((val) => {
    //             if (val.status_asuransi !== "Tidak digunakan") {
    //                 arr.push(val);
    //                 current_status = val.status_asuransi;
    //                 total_asuransi += val.premi;
    //             } else {
    //                 current_status = val.status_asuransi;
    //                 onePremi = val.premi;
    //                 no_polis = val.staco_no_policy;
    //             }
    //         });

    //         const listDataAsuransi = () => {
    //             if (count > 1 && current_status !== "Tidak digunakan") {
    //                 return (
    //                     <>
    //                         <div className="row font-weight-bold click-detail">
    //                             <div className="col-6">
    //                                 <p onClick={this.toggleAsuransi}>Total Asuransi {this.arrow(this.state.showAsuransi)}</p>
    //                             </div>
    //                             <div className="col-6">
    //                                 <NumberFormat
    //                                     value={total_asuransi}
    //                                     displayType={"text"}
    //                                     thousandSeparator={"."}
    //                                     decimalSeparator={","}
    //                                     prefix={"Rp "}
    //                                     renderText={(value) => <span className="float-right">{value}</span>}
    //                                 />
    //                             </div>
    //                         </div>
    //                         <Collapse isOpen={this.state.showAsuransi}>
    //                             <div className="card-table mb-4 ">
    //                                 <Table borderless responsive className="table-asuransi-custom">
    //                                     <thead>
    //                                         <tr>
    //                                             <th>Tanggal</th>
    //                                             <th>Nominal</th>
    //                                             <th className="text-right">Nomor Polis</th>
    //                                         </tr>
    //                                     </thead>
    //                                     <tbody>
    //                                         {arr.map((val) => {
    //                                             const tanggal = moment(new Date(val.tgl_penawaran_pemberian_pinjaman[0])).format("DD/MM/YYYY");
    //                                             return (
    //                                                 <tr key={val._id}>
    //                                                     <td>{tanggal}</td>
    //                                                     <td>
    //                                                         <NumberFormat
    //                                                             value={val.premi}
    //                                                             displayType={"text"}
    //                                                             thousandSeparator={"."}
    //                                                             decimalSeparator={","}
    //                                                             prefix={"Rp "}
    //                                                             renderText={(value) => <span>{value}</span>}
    //                                                         />
    //                                                     </td>
    //                                                     <td className="text-right">
    //                                                         <span style={{ display: "inline-flex" }}>
    //                                                             <p className="text-secondary pr-2">{val.staco_no_policy}</p>
    //                                                             <i className="fa fa-download fa-sm text-primary text-center" aria-hidden="true"></i>
    //                                                         </span>
    //                                                     </td>
    //                                                 </tr>
    //                                             );
    //                                         })}
    //                                     </tbody>
    //                                 </Table>
    //                             </div>
    //                         </Collapse>
    //                     </>
    //                 );
    //             } else {
    //                 return (
    //                     // <p>szxdfghjk</p>
    //                     <>
    //                         <div className="row font-weight-bold">
    //                             <div className="col-6">
    //                                 <p>Nominal Asuransi</p>
    //                             </div>
    //                             <div className="col-6">
    //                                 <span>
    //                                     <NumberFormat
    //                                         value={onePremi}
    //                                         displayType={"text"}
    //                                         thousandSeparator={"."}
    //                                         decimalSeparator={","}
    //                                         prefix={"Rp "}
    //                                         renderText={(value) => <span className="float-md-right">{value}</span>}
    //                                     />
    //                                 </span>
    //                             </div>
    //                         </div>
    //                         <div
    //                             style={{
    //                                 display: current_status === "Tidak digunakan" ? "none" : "block"
    //                             }}>
    //                             <div className="row font-weight-bold">
    //                                 <div className="col-6">
    //                                     <p> Nomor Polis</p>
    //                                 </div>
    //                                 <div className="col-6">
    //                                     <span className=" float-right no-polis">{no_polis}</span>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </>
    //                 );
    //             }
    //         };

    //         return (
    //             <>
    //                 <div className="row font-weight-bold">
    //                     <div className="col-6">
    //                         <p>Status Asuransi</p>
    //                     </div>
    //                     <div className="col-6">
    //                         <span className="float-md-right">{current_status}</span>
    //                     </div>
    //                 </div>

    //                 {listDataAsuransi()}
    //             </>
    //         );
    //     }
    // };

    isSuperLender(data) {
        if (data.akumulasi_lend === undefined) {
            return;
        } else {
            return data.id_pinjaman.premium_lender;
        }
    }

    render() {
        const lending = this.props.lending;
        return (
            <div className="container mt-4 mb-4">
                <div className="row">
                    <div className="col-12 col-xl-6 pb-4">
                        <div className="card container p-4">
                            <p>Pendanaan</p>
                            {this.listPendanaan(lending)}
                        </div>
                    </div>
                    {/* {!this.isSuperLender(lending) && (
                        <div className="col-12 col-xl-6">
                            <div className="card container p-4">
                                <p>Asuransi</p>
                                {this.listAsuransi(lending)}
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        );
    }
}

export default DetailPendanaan;
