import React, { Component } from "react";
import { connect } from "react-redux";
import { LendTotal } from "../../../redux/action/Lender";
import Skeleton from "react-loading-skeleton";
class DasborTotal extends Component {
    componentDidMount = async () => {
        await this.props.dispatch(LendTotal());
    };

    data = (data, output) => {
        if (data) {
            return "Rp. " + output;
        } else {
            return "Rp. 0";
        }
    };
    render() {
        const lenderTotalData = this.props.lender.lenderTotal ? this.props.lender.lenderTotal.data[0] : null;
        const sukubunga = Math.round(lenderTotalData && lenderTotalData.sumInterest);
        return (
            <div className="row mb-4">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="card border-0 shadow card-statistic">
                        <div className="card-body">
                            <div className="d-flex">
                                <i className="text-primary material-icons mr-3">account_box</i>
                                <div>
                                    <p className="text-secondary mb-0">Total Pendanaan</p>
                                    <h4 className="font-weight-semi-bold mb-0">
                                        {this.props.lender.isLoadingTotal ? (
                                            <Skeleton />
                                        ) : (
                                            this.data(lenderTotalData, lenderTotalData && lenderTotalData.sumLending.toLocaleString("IN"))
                                        )}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card border-0 shadow card-statistic">
                        <div className="card-body">
                            <div className="d-flex">
                                <i className="text-primary material-icons mr-3">trending_up</i>
                                <div>
                                    <p className="text-secondary mb-0">Total Bunga</p>
                                    <h4 className="font-weight-semi-bold mb-0">
                                        {this.props.lender.isLoadingTotal ? <Skeleton /> : this.data(lenderTotalData, sukubunga.toLocaleString("IN"))}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(DasborTotal);
