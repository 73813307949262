const initialState = {
  lender: [],
  lenderList: [],
  lenderDetail: [],
  lenderLend: [],
  lenderLendSigned: [],
  pagination: { sign: {}, signed: {} },
  lenderLendNew: [],
  lenderPort: [],
  lenderLaporan: "",
  lenderTotal: null,
  lenderPengkinianData: null,
  isLoading: false,
  isLoadingParent: false,
  isLoadingTotal: false,
  isLoadingLendApp: false,
  isLoadingLendDetail: false,
  isError: false,
  isLoadingLender: false,
  isLoadingUpdateP: false, //personal
  isLoadingUpdateE: false, //entity
  isLoadingLending: false,
  isLoadingLendPort: false,
  isLoadingLendSign: false,
  isLoadingLendSigned: false,
  isLoadingLaporan: false,
  isLoadingEditLender: false,
  isLoadingCheckYearly: false,
  isLoadingudapteYerly: false,
  isLoadingGetLenderPengkinianData: false,
  lenderYearly: {}
};

const ListLender = (state = initialState, action) => {
  switch (action.type) {
    case "LENDER_EDIT_PENDING":
      return {
        ...state,
        isLoadingEditLender: true
      };

    case "LENDER_EDIT_REJECTED":
      return {
        ...state,
        isLoadingEditLender: false,
        isError: true
      };

    case "LENDER_EDIT_FULFILLED":
      return {
        ...state,
        isLoadingEditLender: false,
        lenderDetail: action.payload.data.result
      };
    //
    case "LENDER_ID_PENDING":
      return {
        ...state,
        isLoadingLender: true
      };

    case "LENDER_ID_REJECTED":
      return {
        ...state,
        isLoadingLender: false,
        isError: true
      };

    case "LENDER_ID_FULFILLED":
      return {
        ...state,
        isLoadingLender: false,
        lenderDetail: action.payload.data.result
      };
    //update entity
    case "LENDER_UPDATE_ENTITY_PENDING":
      return {
        ...state,
        isLoadingUpdateE: true
      };
    case "LENDER_UPDATE_ENTITY_REJECTED":
      return {
        ...state,
        isLoadingUpdateE: false,
        isError: true
      };
    case "LENDER_UPDATE_ENTITY_FULFILLED":
      return {
        ...state,
        isLoadingUpdateE: false,
        lenderList: [state.lenderList, action.payload.data]
      };
    //update personal
    case "LENDER_UPDATE_PERSONAL_PENDING":
      return {
        ...state,
        isLoadingUpdateP: true
      };
    case "LENDER_UPDATE_PERSONAL_REJECTED":
      return {
        ...state,
        isLoadingUpdateP: false,
        isError: true
      };
    case "LENDER_UPDATE_PERSONAL_FULFILLED":
      return {
        ...state,
        isLoadingUpdateP: false,
        lenderList: [state.lenderList, action.payload.data]
      };
    case "LENDER_UPDATE_DIGISIGN_PENDING":
      return {
        ...state,
        isLoadingUpdateD: true
      };
    case "LENDER_UPDATE_DIGISIGN_REJECTED":
      return {
        ...state,
        isLoadingUpdateD: false,
        isError: true
      };
    case "LENDER_UPDATE_DIGISIGN_FULFILLED":
      return {
        ...state,
        isLoadingUpdateD: false,
        lenderList: [state.lenderList, action.payload.data]
      };
    //mendanai
    case "LENDING_PENDING":
      return {
        ...state,
        isLoadingLending: true
      };
    case "LENDING_REJECTED":
      return {
        ...state,
        isLoadingLending: false,
        isError: true
      };
    case "LENDING_FULFILLED":
      return {
        ...state,
        isLoadingLending: false,
        lenderDetail: action.payload.data
      };
    //riwayat pembelian proyek
    case "LEND_APPLICATION_PENDING":
      return {
        ...state,
        isLoadingLendApp: true
      };

    case "LEND_APPLICATION_REJECTED":
      return {
        ...state,
        isLoadingLendApp: false,
        isError: true
      };

    case "LEND_APPLICATION_FULFILLED":
      // console.log(action.payload.data.data)
      return {
        ...state,
        isLoadingLendApp: false,
        lenderLend: action.payload.data.data
      };

    //riwayat tanda tangan digital
    case "LEND_SIGN_PENDING":
      return {
        ...state,
        isLoadingLendSign: true
      };

    case "LEND_SIGN_REJECTED":
      return {
        ...state,
        isLoadingLendSign: false,
        isError: true
      };

    case "LEND_SIGN_FULFILLED":
      // console.log(action.payload.data.data);
      return {
        ...state,
        isLoadingLendSign: false,
        lenderLend: action.payload.data.data,
        pagination: {
          ...state.pagination,
          sign: {
            limit: action.payload.data.limit,
            page: action.payload.data.page,
            totalData: action.payload.data.totalData,
            totalPage: action.payload.data.totalPage
          }
        }
      };
    //riwayat tanda tangan digital
    case "LEND_SIGNED_PENDING":
      return {
        ...state,
        isLoadingLendSigned: true
      };

    case "LEND_SIGNED_REJECTED":
      return {
        ...state,
        isLoadingLendSigned: false,
        isError: true
      };

    case "LEND_SIGNED_FULFILLED":
      // console.log(action.payload.data.data)
      return {
        ...state,
        isLoadingLendSigned: false,
        lenderLendSigned: action.payload.data.data,
        pagination: {
          ...state.pagination,
          signed: {
            limit: action.payload.data.limit,
            page: action.payload.data.page,
            totalData: action.payload.data.totalData,
            totalPage: action.payload.data.totalPage
          }
        }
      };

    case "LEND_APP_DETAIL_PENDING":
      return {
        ...state,
        isLoadingLendDetail: true
      };

    case "LEND_APP_DETAIL_REJECTED":
      return {
        ...state,
        isLoadingLendDetail: false,
        isError: true
      };

    case "LEND_APP_DETAIL_FULFILLED":
      return {
        ...state,
        isLoadingLendDetail: false,
        lenderLendNew: action.payload.data.result
      };

    //lender portofolio
    case "LEND_PORTFOLIO_PENDING":
      return {
        ...state,
        isLoadingLendPort: true
      };
    case "LEND_PORTFOLIO_REJECTED":
      return {
        ...state,
        isLoadingLendPort: false,
        isError: true
      };
    case "LEND_PORTFOLIO_FULFILLED":
      // console.log(action.payload.data)
      return {
        ...state,
        isLoadingLendPort: false,
        lenderPort: action.payload.data
      };
    //total lender
    case "LEND_TOTAL_PENDING":
      return {
        ...state,
        isLoadingTotal: true
      };
    case "LEND_TOTAL_REJECTED":
      return {
        ...state,
        isLoadingTotal: false,
        isError: true
      };
    case "LEND_TOTAL_FULFILLED":
      // console.log(action.payload.data)
      return {
        ...state,
        isLoadingTotal: false,
        lenderTotal: action.payload.data
      };
    case "LAPORAN_PPH_PENDING":
      return {
        ...state,
        isLoadingLaporan: true
      };
    case "LAPORAN_PPH_REJECTED":
      return {
        ...state,
        isLoadingLaporan: false,
        isError: true
      };
    case "LAPORAN_PPH_FULFILLED":
      return {
        ...state,
        isLoadingLaporan: false,
        lenderLaporan: action.payload.data
      };
    // check update data lender yearly
    case "CHECK_LENDER_YEARLY_PENDING":
      return {
        ...state,
        isLoadingCheckYearly: true
      };
    case "CHECK_LENDER_YEARLY_REJECTED":
      return {
        ...state,
        isLoadingCheckYearly: false,
        isError: true
      };
    case "CHECK_LENDER_YEARLY_FULFILLED":
      return {
        ...state,
        isLoadingCheckYearly: false,
        lenderYearly: action.payload.data
      };
    case "UPDATE_DATA_YERALY_PENDING":
      return {
        ...state,
        isLoadingudapteYerly: true
      };
    case "UPDATE_DATA_YERALY_REJECTED":
      return {
        ...state,
        isLoadingudapteYerly: false,
        isError: true
      };
    case "UPDATE_DATA_YERALY_FULFILLED":
      return {
        ...state,
        isLoadingudapteYerly: false
      };
    case "GET_LENDER_PENGKINIAN_DATA_PENDING":
      return {
        ...state,
        isLoadingGetLenderPengkinianData: true
      };
    case "GET_LENDER_PENGKINIAN_DATA_REJECTED":
      return {
        ...state,
        isLoadingGetLenderPengkinianData: false,
        isError: true
      };
    case "GET_LENDER_PENGKINIAN_DATA_FULFILLED":
      // console.log(action.payload.data.data)
      return {
        ...state,
        isLoadingGetLenderPengkinianData: false,
        isError: false,
        lenderPengkinianData: action.payload.data.data
      };
    default:
      return state;
  }
};
export default ListLender;
