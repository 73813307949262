import React, { Component } from "react";
import Content from "./PortoContent";
import Info from "./PortoInfo";
import { NavbarTitle } from "../../../library/components/NavbarComponent";
import { connect } from "react-redux";
import { LendApp } from "../../../redux/action/Lender";
class Portofolio extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            project: []
        };
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(LendApp());
        this.setState({
            project: this.props.lender.lenderLend
        });
        // console.log(this.state.project);
    };
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const title = ["Pendanaan Dana", "Pendanaan Outstanding", "Pendanaan Selesai"];
        return (
            <div>
                <div className="portfolio-wrapper">
                    <Info />
                    <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                    <Content activeTab={this.state.activeTab} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(Portofolio);
