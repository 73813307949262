import React, { Component } from "react";
import { LendPort } from "../../../redux/action/Lender";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import NumberFormat from "react-number-format";

class PortoInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            outstanding: "",
            dibayar: "",
            berjalan: "",
            popoverOut: false,
            popoverDone: false
        };
    }

    toggleInfo() {
        this.setState({
            popoverOut: !this.state.popoverOut
        });
    }

    toggleInfoDone() {
        this.setState({
            popoverDone: !this.state.popoverDone
        });
    }

    onHover = () => {
        this.setState({
            popoverOut: true
        });
    };
    onHoverDone = () => {
        this.setState({
            popoverDone: true
        });
    };

    onHoverLeave = () => {
        this.setState({
            popoverOut: false
        });
    };
    onHoverLeaveDone = () => {
        this.setState({
            popoverDone: false
        });
    };
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(LendPort());
        this.setState({
            berjalan: Math.round(this.props.lender.lenderPort.pendingAmount),
            outstanding: Math.round(this.props.lender.lenderPort.berjalanAmount),
            dibayar: Math.round(this.props.lender.lenderPort.selesaiAmount)
        });
    };

    render() {
        return (
            <div className="row mb-4">
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <div
                        className="card border-0 shadow
                         card-statistic">
                        <div className="card-body">
                            <p className="text-secondary mb-0">Pendanaan Berjalan</p>
                            <h4 className="font-weight-semi-bold mb-0">
                                {this.props.lender.isLoadingLendPort ? (
                                    <Skeleton />
                                ) : (
                                    <NumberFormat
                                        value={this.state.berjalan}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={0}
                                        prefix={"Rp "}
                                        renderText={(value) => <span>{value}</span>}
                                    />
                                )}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <div
                        className="card border-0 shadow
                         card-statistic">
                        <div className="card-body">
                            <p className="text-secondary mb-0">
                                Pendanaan Outstanding{" "}
                                <span id="Popovers3" data-html="true" onMouseEnter={this.onHover} onMouseLeave={this.onHoverLeave}>
                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "15px" }}>
                                        info
                                    </i>
                                </span>
                                <Popover
                                    // className="d-none d-sm-block"
                                    placement="bottom"
                                    isOpen={this.state.popoverOut}
                                    target="Popovers3"
                                    toggle={this.toggleInfo.bind(this)}>
                                    <PopoverHeader className="popover-header"></PopoverHeader>
                                    <PopoverBody>Pinjaman Outstanding adalah sisa pokok pinjaman yang belum dibayar</PopoverBody>
                                </Popover>
                            </p>

                            <h4 className="font-weight-semi-bold mb-0">
                                {this.props.lender.isLoadingLendPort ? (
                                    <Skeleton />
                                ) : (
                                    <NumberFormat
                                        value={this.state.outstanding}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={0}
                                        prefix={"Rp "}
                                        renderText={(value) => <span>{value}</span>}
                                    />
                                )}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <div
                        className="card border-0 shadow
                         card-statistic">
                        <div className="card-body">
                            <p className="text-secondary mb-0">
                                Pendanaan Selesai
                                <span id="Popovers4" data-html="true" onMouseEnter={this.onHoverDone} onMouseLeave={this.onHoverLeaveDone}>
                                    <i className="material-icons-outlined text-primary" style={{ fontSize: "15px" }}>
                                        info
                                    </i>
                                </span>
                                <Popover
                                    // className="d-none d-sm-block"
                                    placement="bottom"
                                    isOpen={this.state.popoverDone}
                                    target="Popovers4"
                                    toggle={this.toggleInfoDone.bind(this)}>
                                    <PopoverHeader className="popover-header"></PopoverHeader>
                                    <PopoverBody>Pinjaman Dibayar adalah total pokok pinjaman sudah dibayarkan</PopoverBody>
                                </Popover>
                            </p>
                            <h4 className="font-weight-semi-bold mb-0">
                                {this.props.lender.isLoadingLendPort ? (
                                    <Skeleton />
                                ) : (
                                    <NumberFormat
                                        value={this.state.dibayar}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={0}
                                        prefix={"Rp "}
                                        renderText={(value) => <span>{value}</span>}
                                    />
                                )}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(PortoInfo);
