import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { colorGrade, sukuBunga, satuanJWP, namaSektor } from "../../../library/helper/helper";
import moment from "moment";
import "moment/locale/id";

class DetailInfo extends Component {
    render() {
        const project = this.props.project;
        const progressBar = (project.nilai_total_peminjam / project.nilai_pengajuan_pinjaman) * 100;

        const sisaJatuhTempo = () => {
            const rincian = project.rincian_pinjaman;
            const length = rincian && rincian.length - 1;
            const dataRincian = rincian && rincian[length];
            const jatuhTempo = dataRincian && dataRincian.jatuh_tempo;
            const statusBayar = dataRincian && dataRincian.status_bayar;
            const date1 = new Date(new Date().setHours(7, 0, 0, 0));
            const date2 = new Date(jatuhTempo);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (date1 > date2 && statusBayar === false) {
                return <p className="card-text value-info"> {"- " + diffDays + " hari"}</p>;
            } else if (statusBayar === true) {
                return <p className="card-text value-info"> {new Date(jatuhTempo).toLocaleDateString("IN")}</p>;
            } else {
                return <p className="card-text value-info"> {diffDays + " hari lagi"}</p>;
            }
        };
        return (
            <div className="project-body">
                <p className="banner-grade-detail mb-4" style={{ backgroundColor: colorGrade(project.grade_proyek) }}>
                    {"Grade " + project.grade_proyek}
                </p>
                <div style={{ display: "flex" }} className="mb-4">
                    <div className="nama-pinjaman font-weight-bold">{project.nama_pinjaman} </div>
                    <div
                        style={{
                            width: "5px",
                            height: "5px",
                            backgroundColor: "#848484",
                            borderRadius: "50%",
                            margin: "13px"
                        }}></div>
                    <div className="tipe-pinjaman text-secondary" style={{ position: "relative", alignSelf: "center" }}>
                        {project.tipe_pinjaman}
                    </div>
                </div>
                <p>
                    Terkumpul{" "}
                    <NumberFormat
                        value={project.nilai_total_peminjam}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"Rp "}
                        renderText={(value) => <b>{value}</b>}
                    />{" "}
                    dari{" "}
                    <NumberFormat
                        value={project.nilai_pengajuan_pinjaman}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"Rp "}
                        renderText={(value) => <b>{value}</b>}
                    />
                </p>
                <div className="progress">
                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${parseInt(progressBar)}%` }}></div>
                </div>

                <div className="row">
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Nama borrower</p>
                        <p className="card-text value-info">{project.borrower && project.borrower.nama_borrower}</p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Sektor usaha</p>
                        <p className="card-text value-info">{namaSektor(project.penggunaan_pinjaman)}</p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Tanggal mulai proyek</p>
                        <p className="card-text value-info">{moment(new Date(project.tgl_perjanjian_pinjaman)).locale("id").format("LL")}</p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Jatuh tempo</p>
                        {sisaJatuhTempo()}
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Durasi peminjaman</p>
                        <p className="card-text value-info">
                            {project.jangka_waktu_pinjaman} {satuanJWP(project.satuan_jangka_waktu_pinjaman)}
                        </p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Suku bunga</p>
                        <p className="card-text value-info">
                            {sukuBunga(
                                project.tipe_pinjaman,
                                project.jenis_pinjaman,
                                project.suku_bunga_pinjaman,
                                project.suku_bunga_konsumtif,
                                project.suku_bunga_micro,
                                project.spread_bunga
                            )}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailInfo;
