export function validate(cekKey, value, data1, data2, jenis_pinjaman) {
    const number = /^\d*$/;
    const string = /^[a-zA-Z ]*$/;
    const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
    //password 8 karakter, angka, simbol, huruf besar dan huruf kecil
    var regex = [];
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.
    var passed = 0;
    //Validate for each Regular Expression.
    for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(value)) {
            passed++;
        }
    }
    //

    let errorMsg = 0;

    switch (value.length > 0) {
        case true:
            switch (cekKey) {
                case "email":
                    switch (!email.test(value)) {
                        case true:
                            errorMsg = "Format email salah, periksa kembali";
                            break;
                        case false:
                            errorMsg = 0;
                            break;
                        default:
                            break;
                    }
                    break;
                case "number":
                    switch (!number.test(value)) {
                        case true:
                            errorMsg = "Hanya berupa angka";
                            break;
                        case false:
                            errorMsg = 0;
                            break;
                        default:
                            break;
                    }
                    break;
                case "currency":
                    switch (value.replace(/\D/g, "").replace(/^0+/, "") < 50000) {
                        case true:
                            errorMsg = "Nominal kurang dari 50.000";
                            break;
                        case false:
                            errorMsg = 0;
                            break;
                        default:
                            break;
                    }
                    break;
                case "string":
                    switch (!string.test(value)) {
                        case true:
                            errorMsg = "Hanya berupa huruf";
                            break;
                        case false:
                            errorMsg = 0;
                            break;
                        default:
                            break;
                    }
                    break;
                case "passwordLogin":
                    switch (value.length < 8) {
                        case true:
                            errorMsg = "Kata sandi harus melebihi 8 karakter";
                            break;
                        case false:
                            errorMsg = 0;
                            break;
                        default:
                            break;
                    }
                    break;
                case "password":
                    switch (value.length < 8) {
                        case true:
                            errorMsg = "Kata sandi harus melebihi 8 karakter";
                            break;
                        case false:
                            switch (passed) {
                                case 1:
                                case 2:
                                case 3:
                                    errorMsg = "Kata sandi harus mengandung simbol, angka, huruf besar, huruf kecil";
                                    break;
                                case 4:
                                    errorMsg = 0;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "passwordConfirm":
                    switch (value.length < 8) {
                        case true:
                            errorMsg = "Kata sandi harus melebihi 8 karakter";
                            break;
                        case false:
                            switch (passed) {
                                case 1:
                                case 2:
                                case 3:
                                    errorMsg = "Kata sandi harus mengandung simbol, angka, huruf besar, huruf kecil";
                                    break;
                                case 4:
                                    switch (data2 !== data1) {
                                        case true:
                                            errorMsg = "Password harus sama";
                                            break;
                                        case false:
                                            errorMsg = 0;
                                            break;
                                        default:
                                            break;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "tenor":
                    switch (!number.test(value)) {
                        case true:
                            errorMsg = "Hanya berupa angka";
                            break;
                        case false:
                            switch (value > 12) {
                                case true:
                                    errorMsg = "Lewat dari batas yang ditentukan";
                                    break;
                                case false:
                                    errorMsg = 0;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "plafondSisa":
                    switch (value.replace(/\D/g, "") < 50000) {
                        case true:
                            errorMsg = "Nominal kurang dari 50.000";
                            break;
                        case false:
                            //kenapa password, karena var SISA dari parent masuk ke child dgn nama PASSWORD. agar tidak bingung maka di ganti dengan var sisa spt di bawah
                            var sisa = data1;
                            switch (value.replace(/\D/g, "") > sisa) {
                                case true:
                                    errorMsg = "Nominal pengajuan melebihi plafond yang ditentukan";
                                    break;
                                case false:
                                    errorMsg = 0;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "danaiIndofund":
                    switch (value.replace(/\D/g, "").replace(/^0+/, "") < 50000) {
                        case true:
                            errorMsg = "Nominal kurang dari 50.000";
                            break;
                        case false:
                            //kenapa data1, karena var SISA dari parent masuk ke child dgn nama DATA1. agar tidak bingung maka di ganti dengan var sisa spt di bawah
                            var sisaDana = data1;
                            var saldo = data2;
                            switch (value.replace(/\D/g, "") % 50000 !== 0) {
                                case true:
                                    errorMsg = "Nominal bukan kelipatan Rp. 50.000";
                                    break;
                                case false:
                                    switch (value.replace(/\D/g, "") > saldo) {
                                        case true:
                                            errorMsg = "Nominal yang dimasukkan kurang dari saldo Anda";
                                            break;
                                        case false:
                                            switch (value.replace(/\D/g, "") > sisaDana) {
                                                case true:
                                                    errorMsg = "Nominal yang Anda masukkan melebihi limit pendanaan";
                                                    break;
                                                case false:
                                                    errorMsg = 0;
                                                    break;
                                                default:
                                                    break;
                                            }
                                            break;
                                        default:
                                            break;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "danaiNoValidate":
                    const nilaiPendanaan = parseInt(value.replace(/\D/g, ""));
                    //kenapa password, karena var SISA dari parent masuk ke child dgn nama PASSWORD. agar tidak bingung maka di ganti dengan var sisa spt di bawah
                    const sisaPlafon = data1;
                    const saldoLender = data2;

                    if (jenis_pinjaman !== "Micro Bullet Reguler" && value.replace(/\D/g, "").replace(/^0+/, "") < 50000) {
                        return (errorMsg = "Nominal kurang dari 50.000");
                    }

                    switch (nilaiPendanaan > saldoLender) {
                        case true:
                            errorMsg = "Nominal yang dimasukkan kurang dari saldo Anda";
                            break;
                        case false:
                            switch (nilaiPendanaan > sisaPlafon) {
                                case true:
                                    errorMsg = "Nominal yang Anda masukkan melebihi limit pendanaan";
                                    break;
                                case false:
                                    errorMsg = 0;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "tarikSaldo":
                    const nominalPenarikan = value.replace(/\D/g, "");
                    switch (nominalPenarikan < 50000) {
                        case true:
                            errorMsg = "Nominal kurang dari 50.000";
                            break;
                        case false:
                            var dompet = data1;
                            switch (nominalPenarikan > dompet) {
                                case true:
                                    errorMsg = "Nominal melebihi saldo Anda";
                                    break;
                                case false:
                                    errorMsg = 0;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            return errorMsg;
        // break;
        case false:
            errorMsg = "Tidak boleh kosong";
            return errorMsg;
        default:
            break;
    }
}
