import React, { Component } from "react";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { sukuBunga } from "../../../library/helper/helper";

class CMenggalangDana extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            currentPagePengumpulan: 1,
            todosPerPagePengumpulan: 15,
            filter: ""
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPagePengumpulan: pageNumber
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };

    MenggalangDana = (currentPagePengumpulan, todosPerPagePengumpulan, lengthPengumpulan) => {
        if (this.props.data === undefined) {
            return <div></div>;
        } else {
            const indexOfLastTodo = currentPagePengumpulan * todosPerPagePengumpulan;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPagePengumpulan;
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }

            const currentTodos = this.props.data
                .filter((item) =>
                    this.state.filter === ""
                        ? (item.id_pinjaman && item.id_pinjaman.status_proyek === "Menggalang dana") ||
                          (item.id_pinjaman && item.id_pinjaman.status_proyek === "Proyek sedang berjalan")
                        : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase()) &&
                          // (
                          ((item.id_pinjaman && item.id_pinjaman.status_proyek === "Menggalang dana") || "Proyek sedang berjalan")
                )
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);

            if (currentTodos !== 0 && lengthPengumpulan !== 0) {
                const hasil = currentTodos.map((val) => {
                    return (
                        <div key={val._id}>
                            <div className="transaction p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-12 mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-3 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                        <p className="mb-0" style={{ display: "flex" }}>
                                                            {val.id_pinjaman.nama_pinjaman}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-1 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                                        <p className="mb-0">
                                                            {sukuBunga(
                                                                val.id_pinjaman.tipe_pinjaman,
                                                                val.id_pinjaman.jenis_pinjaman,
                                                                val.id_pinjaman.suku_bunga_pinjaman,
                                                                val.id_pinjaman.suku_bunga_konsumtif,
                                                                val.id_pinjaman.suku_bunga_micro,
                                                                val.id_pinjaman.spread_bunga,
                                                                true
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                                        <p className="mb-0">Rp {val.total_lend.toLocaleString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Status Proyek</p>
                                                        <p className="mb-0">{val.id_pinjaman.status_proyek}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Tanggal Mulai Project</p>
                                                        <p className="mb-0">{new Date(val.id_pinjaman.createdAt).toLocaleDateString("IN")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
                return hasil;
            } else {
                return (
                    <div className="transactions-wrapper m-n3">
                        <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Belum ada Transaksi"} />
                        <div className="text-center">
                            <a href="/proyek" className="btn btn-outline-primary btn-mulai-dana">
                                Mulai Danai Sekarang
                            </a>
                        </div>
                    </div>
                );
            }
        }
    };
    render() {
        const { currentPagePengumpulan, todosPerPagePengumpulan } = this.state;
        const { filter } = this.state;

        const lengthPengumpulan = this.props.data.filter((item) =>
            this.state.filter === ""
                ? (item.id_pinjaman && item.id_pinjaman.status_proyek === "Menggalang dana") ||
                  (item.id_pinjaman && item.id_pinjaman.status_proyek === "Proyek sedang berjalan")
                : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase())
        ).length;

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between my-3">
                    {/* <div>
                    <label className="mb-0 mr-2 d-none d-md-inline-block" for="urutkan">Urutkan</label>
                    <select id="urutkan" className="custom-select w-auto mr-2">
                        <option value="">Suku Bunga</option>
                    </select>
                </div> */}
                    <div>
                        <form>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    placeholder="Cari Transaksi"
                                    value={filter}
                                    onChange={this.handleChangeSearch}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <i className="material-icons-outlined">search</i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.MenggalangDana(currentPagePengumpulan, todosPerPagePengumpulan, lengthPengumpulan)}

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPagePengumpulan}
                        itemsCountPerPage={this.state.todosPerPagePengumpulan}
                        totalItemsCount={parseInt(lengthPengumpulan)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default CMenggalangDana;
