import React, { Component } from "react";
import { connect } from "react-redux";
import { LenderId, LenderUpdateEntity, LenderUpdateDigisign } from "../../../../redux/action/Lender";
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Input, Button } from "reactstrap";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../sass/datepicker.css";
import "../../../../sass/loader.css";
import { addYears } from "date-fns";

import {
    InputIcon,
    Inputs,
    ModalFoto,
    FormLender,
    FormSearch,
    ShowFoto,
    InputFotoKTP,
    IconVerifLender,
    OpenCamera,
    InputFotoSelfie
} from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";
import { CheckDigisignData, InfoBrowser, changeCapitalize, imgToFile } from "../../../../library/helper/helper";
import { LoadingBadanHukum } from "../../../assets/loading data/LoadingLender";
import Location from "../../../assets/svg/Location";
import { Master } from "../../../../redux/action/Master";


class DatadiriEntity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            nama_pengguna: "",
            nama_lender: "",
            no_npwp: "",
            id_jenis_badan_hukum: "",
            id_negara_domisili: "",
            alamat: "",
            id_provinsi: "",
            id_kota: "",
            kode_pos: "",
            id_bidang_pekerjaan: "",
            aset: "",
            sumber_dana: "",
            nama_perwakilan: "",
            no_ktp_perwakilan: "",
            foto_ktp: "",
            foto_selfie: "",
            file: "",
            modal: false,
            setModal: false,
            nama_bank: "",
            nomor_akun_bank: "",
            modalktp: false,
            modalselfie: false,
            checked: false,
            disabled: true,
            popoverOpen: false,
            errKtp: "",
            errNumber: "",
            tgl_lahir: addYears(new Date(), -17),
            email: "",
            kelurahan: "",
            kecamatan: "",
            jabatan_perwakilan: "",
            alamat_perwakilan: "",
            provinsi_perwakilan: "",
            kota_perwakilan: "",
            kelurahan_perwakilan: "",
            kecamatan_perwakilan: "",
            kode_pos_perwakilan: "",
            camera: true,
            fotoDone: false,
            modalselfieWebcam: false,
            id_jenis_kelamin: "",
            tempat_lahir: "",
            negaraDomisili: "",
            provinsi: "",
            kota: "",
            kotaP: "",
            provinsiP: "",
            no_handphone: "",
            uploadFotoSelfie: false,
            update_digisign: "",
            errEmail: "",
            errNpwp: "",
            errNama: "",
            errTempatLahir: "",
            errJabatan: "",
            errKelurahan: "",
            errkecamatan: "",
            errKodepos: "",
            kotaList: [],
            kotaListDomisili: []
        };
        this.dateChanged = this.dateChanged.bind(this);
        this.fotoLagi = this.fotoLagi.bind(this);
        this.successFoto = this.successFoto.bind(this);
    }
    //js

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        });
        //    console.log(e.target.files[0])
    };

    handleSelectSearch = (name, e) => {
        // console.log({[name] : e.value})
        this.setState({ [name]: e.value });
    };

    togglemodal() {
        this.setState({
            modal: !this.state.modal,
            setModal: !this.state.setModal
        });
    }

    dateChanged(d) {
        this.setState({ tgl_lahir: d });
    }

    // foto camera
    toggleOpenCamera() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }
    handleTakePhotoAnimationDone(dataUri) {
        this.setState({
            foto_selfie: dataUri,
            camera: false
        });
    }
    fotoLagi() {
        this.setState({
            camera: !this.state.camera
        });
    }
    successFoto = () => {
        this.setState({
            modalselfieWebcam: false,
            setModal: false,
            fotoDone: true
        });
    };
    //

    //readonly
    readOnly() {
        if ((this.state.update_lender === true && this.state.kyc === false) || (this.state.update_lender === true && this.state.kyc === true)) {
            return true;
        } else {
            return false;
        }
    }

    authReadonlyDigisign() {
        if (this.state.update_digisign === true) {
            return false;
        } else {
            return true;
        }
    }

    //verif icon
    authVerifikasiIcon = () => {
        if (this.state.kyc === true) {
            return (
                <IconVerifLender
                    onMouseEnter={this.onHover}
                    onMouseLeave={this.onHoverLeave}
                    icon={
                        <i className="material-icons text-success" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                            check_circle
                        </i>
                    }
                    isOpen={this.state.popoverOpen}
                    toggle={this.togglePopover.bind(this)}
                    info={"Anda sudah terverifikasi"}
                />
            );
        } else {
            if (this.state.update_lender === true) {
                return (
                    <IconVerifLender
                        onMouseEnter={this.onHover}
                        onMouseLeave={this.onHoverLeave}
                        icon={
                            <i className="material-icons text-danger" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                                update
                            </i>
                        }
                        isOpen={this.state.popoverOpen}
                        toggle={this.togglePopover.bind(this)}
                        info={"Tahap verifikasi data"}
                    />
                );
            } else {
                return (
                    <IconVerifLender
                        onMouseEnter={this.onHover}
                        onMouseLeave={this.onHoverLeave}
                        icon={
                            <i className="material-icons text-danger" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                                cancel
                            </i>
                        }
                        isOpen={this.state.popoverOpen}
                        toggle={this.togglePopover.bind(this)}
                        info={"Data Anda belum terisi"}
                    />
                );
            }
        }
    };
    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }
    onHover = () => {
        this.setState({
            popoverOpen: true
        });
    };
    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        });
    };

    // foto ktp & selfie
    validFotoKTP = () => {
        if (this.state.update_lender === true) {
            return <ShowFoto label={"Foto KTP"} pc={"Sudah Foto KTP"} onClick={this.togglemodalktp.bind(this)} />;
        } else {
            return <InputFotoKTP forLabel={"foto_ktp"} label={"Foto KTP"} onChange={this.handleUploadFile} name={"foto_ktp"} />;
        }
    };
    validFotoSelfie = () => {
        if (this.state.update_lender === false || this.state.update_digisign === true) {
            return (
                <InputFotoSelfie
                    forLabel={"foto_selfie"}
                    label={"Foto Selfie"}
                    onClick={this.toggleOpenCamera.bind(this)}
                    fotoDone={this.state.fotoDone}
                />
            );
        } else {
            return <ShowFoto label={"Foto Selfie"} pc={"Sudah Foto Selfie"} onClick={this.togglemodalselfie.bind(this)} />;
        }
    };
    togglemodalktp() {
        this.setState({
            modalktp: !this.state.modalktp,
            setModal: !this.state.setModal
        });
    }
    togglemodalselfie() {
        this.setState({
            modalselfie: !this.state.modalselfie,
            setModal: !this.state.setModal
        });
    }
    //end foto

    //auth tanggal lahir
    authTglLahir() {
        if ((this.state.update_lender === true && this.state.kyc === false) || (this.state.update_lender === true && this.state.kyc === true)) {
            return (
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="material-icons-outlined">calendar_today</i>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="text"
                        name="tgl_lahir"
                        placeholder="Contoh: 1996-11-23"
                        value={new Date(this.state.tgl_lahirLender).toLocaleDateString("IN") || ""}
                        onChange={this.handleChange.bind(this)}
                        readOnly
                    />
                </InputGroup>
            );
        } else {
            return (
                // <div>
                // <br/>
                <DatePicker
                    className="form-control"
                    autoComplete="off"
                    dateFormat="yyyy-MM-dd"
                    selected={this.state.tgl_lahir}
                    onChange={this.dateChanged}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    name="tgl_lahir"
                    maxDate={addYears(new Date(), -17)}
                    withPortal
                />
                // </div>
            );
        }
    }

    //check simpan data
    confirmDataValid() {
        if (this.state.update_digisign === true) {
            return (
                <div>
                    <div className="mt-4 mb-5">
                        <div className="custom-control custom-checkbox filter-input">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="agreement"
                                name="agreement"
                                onChange={this.handleCheck}
                                defaultChecked={this.state.checked || ""}
                            />
                            <label className="custom-control-label" htmlFor="agreement">
                                Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, dan saya telah melakukan pengecekan atas
                                kebenaran data-data tersebut.
                            </label>
                        </div>
                    </div>
                    <div className="text-center">
                        {this.props.lender.isLoadingUpdateD ? (
                            <Button disabled color="primary" onClick={this.handleUpdateDigisign} className="btn btn-lg col-12 col-md-6">
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button
                                disabled={this.state.disabled}
                                color="primary"
                                onClick={this.handleUpdateDigisign}
                                className="btn btn-lg col-12 col-md-6">
                                Simpan
                            </Button>
                        )}
                    </div>
                </div>
            );
        } else if (this.state.update_lender === false) {
            return (
                <div>
                    <div className="mt-4 mb-5">
                        <div className="custom-control custom-checkbox filter-input">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="agreement"
                                name="agreement"
                                onChange={this.handleCheck}
                                defaultChecked={this.state.checked || ""}
                            />
                            <label className="custom-control-label" htmlFor="agreement">
                                Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, saya telah melakukan pengecekan atas
                                kebenaran data-data tersebut, dan saya telah membaca
                                <a
                                    className="font-weight-semi-bold text-primary"
                                    href={"/privacy-policy"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ cursor: "pointer" }}>
                                    {" "}
                                    ketentuan privacy dan cookies
                                </a>{" "}
                                .
                            </label>
                        </div>
                    </div>
                    <div className="text-center">
                        {this.props.lender.isLoadingUpdateE ? (
                            <Button disabled color="primary" onClick={this.handleUpdate} className="btn btn-lg col-12 col-md-6">
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button disabled={this.state.disabled} color="primary" onClick={this.handleUpdate} className="btn btn-lg col-12 col-md-6">
                                Simpan
                            </Button>
                        )}
                    </div>
                </div>
            );
        }
    }

    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };
    //
    async componentDidUpdate(prevProps, prevState) {
        if (this.state.id_provinsi !== prevState.id_provinsi) {
            await this.props.dispatch(Master("master_kota_kabupaten", `${this.state.id_provinsi}`));
            this.setState({
                kotaList: this.props.master.masterList
            });
        }
        if (this.state.provinsi_perwakilan !== prevState.provinsi_perwakilan) {
            await this.props.dispatch(Master("master_kota_kabupaten", `${this.state.provinsi_perwakilan}`));
            this.setState({
                kotaListDomisili: this.props.master.masterList
            });
        }
    }

    // function button
    handleUpdate = async () => {
        const {
            nama_lender,
            no_npwp,
            id_jenis_badan_hukum,
            id_jenis_kelamin,
            tgl_lahir,
            id_negara_domisili,
            kecamatan,
            kelurahan,
            alamat,
            id_provinsi,
            id_kota,
            kode_pos,
            id_bidang_pekerjaan,
            aset,
            sumber_dana,
            nama_perwakilan,
            no_ktp_perwakilan,
            foto_ktp,
            foto_selfie,
            jabatan_perwakilan,
            alamat_perwakilan,
            provinsi_perwakilan,
            kota_perwakilan,
            kelurahan_perwakilan,
            kecamatan_perwakilan,
            kode_pos_perwakilan,
            tempat_lahir,
            no_handphone
        } = this.state;

        const formData = new FormData();
        formData.append("nama_lender", nama_lender);
        formData.append("no_handphone", no_handphone);
        formData.append("no_npwp", no_npwp);
        formData.append("id_jenis_badan_hukum", id_jenis_badan_hukum);
        formData.append("id_negara_domisili", id_negara_domisili);
        formData.append("alamat", alamat);
        formData.append("jabatan_perwakilan", changeCapitalize(jabatan_perwakilan));
        formData.append("alamat_perwakilan", alamat_perwakilan);
        formData.append("provinsi_perwakilan", provinsi_perwakilan);
        formData.append("kota_perwakilan", kota_perwakilan);
        formData.append("kelurahan_perwakilan", changeCapitalize(kelurahan_perwakilan));
        formData.append("kecamatan_perwakilan", changeCapitalize(kecamatan_perwakilan));
        formData.append("kode_pos_perwakilan", kode_pos_perwakilan);
        formData.append("id_provinsi", id_provinsi);
        formData.append("tempat_lahir", changeCapitalize(tempat_lahir));
        formData.append("tgl_lahir", tgl_lahir);
        formData.append("id_jenis_kelamin", id_jenis_kelamin);
        formData.append("id_kota", id_kota);
        formData.append("kode_pos", kode_pos);
        formData.append("id_bidang_pekerjaan", id_bidang_pekerjaan);
        formData.append("aset", aset);
        formData.append("sumber_dana", changeCapitalize(sumber_dana));
        formData.append("nama_perwakilan", changeCapitalize(nama_perwakilan));
        formData.append("no_ktp_perwakilan", no_ktp_perwakilan);
        formData.append("kelurahan", changeCapitalize(kelurahan));
        formData.append("kecamatan", changeCapitalize(kecamatan));
        formData.append("foto_ktp", foto_ktp);
        formData.append("foto_selfie", imgToFile(foto_selfie));

        if (
            id_jenis_badan_hukum === -1 ||
            provinsi_perwakilan === -1 ||
            id_provinsi === -1 ||
            id_bidang_pekerjaan === -1 ||
            id_negara_domisili === -1 ||
            aset === -1 ||
            id_jenis_badan_hukum === 0 ||
            !kota_perwakilan ||
            !id_kota ||
            tgl_lahir.toDateString() === new Date().toDateString() ||
            !kecamatan ||
            !kelurahan ||
            !alamat ||
            !kode_pos ||
            !sumber_dana ||
            !nama_perwakilan ||
            !no_ktp_perwakilan ||
            !foto_ktp ||
            !jabatan_perwakilan ||
            !alamat_perwakilan ||
            !kelurahan_perwakilan ||
            !kecamatan_perwakilan ||
            !kode_pos_perwakilan ||
            !tempat_lahir ||
            !no_npwp ||
            !foto_selfie ||
            this.state.errKtp === true
        ) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Mohon untuk periksa kembali data anda",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            await this.props
                .dispatch(LenderUpdateEntity(this.props.id, formData))
                .then(() => {
                    Swal.fire({
                        title: "update data",
                        text: "Data anda berhasil di rubah, selanjutnya silahkan mengisi rekening Anda",
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    });
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Gagal",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        }
    };

    handleUpdateDigisign = async () => {
        const { email, no_handphone, foto_selfie } = this.state;
        const formData = new FormData();
        formData.append("email", email);
        formData.append("no_handphone", no_handphone);
        formData.append("image", imgToFile(foto_selfie));

        await this.props
            .dispatch(LenderUpdateDigisign(formData))
            .then((val) => {
                window.scrollTo(0, 0);

                return Swal.fire({
                    title: "Update Data",
                    text: "Data anda berhasil di rubah. Data anda akan di validasi kembali",
                    icon: "success",
                    button: "ok"
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                // console.log(err.response)
                Swal.fire({
                    title: "Gagal Update",
                    text: `${err.response.data.message}`,
                    icon: "warning",
                    button: "ok"
                });
            });
    };
    //
    async componentDidMount() {
        window.scrollTo(0, 0);

        await this.props.dispatch(LenderId(this.props.id));
        this.setState({
            nama_lender: this.props.lender.lenderDetail.nama_lender,
            alamat: this.props.lender.lenderDetail.alamat,
            kode_pos: this.props.lender.lenderDetail.kode_pos,
            sumber_dana: this.props.lender.lenderDetail.sumber_dana,
            nama_perwakilan: this.props.lender.lenderDetail.nama_perwakilan,
            no_ktp_perwakilan: this.props.lender.lenderDetail.no_ktp_perwakilan,
            no_npwp: this.props.lender.lenderDetail.no_npwp,
            foto_ktp: this.props.lender.lenderDetail.foto_ktp,
            foto_selfie: this.props.lender.lenderDetail.foto_selfie,
            kyc: this.props.lender.lenderDetail.admin_kyc,
            update_lender: this.props.lender.lenderDetail.update_lender,
            update_digisign: this.props.lender.lenderDetail.update_digisign,
            kecamatan: this.props.lender.lenderDetail.kecamatan,
            kelurahan: this.props.lender.lenderDetail.kelurahan,
            tgl_lahirLender: this.props.lender.lenderDetail.tgl_lahir,
            jabatan_perwakilan: this.props.lender.lenderDetail.jabatan_perwakilan,
            alamat_perwakilan: this.props.lender.lenderDetail.alamat_perwakilan,
            kelurahan_perwakilan: this.props.lender.lenderDetail.kelurahan_perwakilan,
            kecamatan_perwakilan: this.props.lender.lenderDetail.kecamatan_perwakilan,
            kode_pos_perwakilan: this.props.lender.lenderDetail.kode_pos_perwakilan,
            no_handphone: this.props.lender.lenderDetail.pengguna.no_handphone,
            email: this.props.lender.lenderDetail.pengguna.email,
            tempat_lahir: this.props.lender.lenderDetail.tempat_lahir
        });
        if (this.state.update_lender === true) {
            this.setState({
                id_jenis_badan_hukum: this.props.lender.lenderDetail.ojkJson.jenisBadanHukum.keterangan,
                id_negara_domisili: this.props.lender.lenderDetail.ojkJson.negaraDomisili.keterangan,
                id_provinsi: this.props.lender.lenderDetail.ojkJson.provinsi.keterangan,
                id_kota: this.props.lender.lenderDetail.ojkJson.kota.keterangan,
                id_bidang_pekerjaan: this.props.lender.lenderDetail.ojkJson.bidangPekerjaan.keterangan,
                aset: this.props.lender.lenderDetail.ojkJson.aset.keterangan,
                id_jenis_kelamin: this.props.lender.lenderDetail.ojkJson.jenisKelamin.keterangan,
                provinsi_perwakilan: this.props.lender.lenderDetail.ojkJson.provinsiPerwakilan.keterangan,
                kota_perwakilan: this.props.lender.lenderDetail.ojkJson.kotaPerwakilan.keterangan
            });
        }
        // console.log(this.props.lender.lenderDetail.ojkJson);

        //if else using opera mini /mozila firefox
        if (this.state.update_lender !== true) {
            InfoBrowser();
        } else {
            CheckDigisignData(this.state.update_digisign, true);
        }
    }
    form = () => {
        return (
            <div className="card-body">
                <Form>
                    <h5 className="font-weight-bold pb-4 text-primary">Informasi Perusahaan</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="nama-lengkap">Nama Lender</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="material-icons-outlined">account_circle</i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="nama_lender"
                                        type="text"
                                        placeholder="Contoh: John Doe"
                                        value={this.state.nama_lender || ""}
                                        onChange={this.handleChange.bind(this)}
                                        className="col-md-10"
                                        readOnly
                                    />
                                    {this.authVerifikasiIcon()}
                                </InputGroup>
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"email"}
                                label={"Email"}
                                icon={<i className="material-icons-outlined">mail </i>}
                                type={"text"}
                                name={"email"}
                                value={this.state.email}
                                readOnly={this.authReadonlyDigisign()}
                                onChange={(e) => this.handleChange(e, "string", "errEmail")}
                                error={this.state.errEmail}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_npwp"}
                                label={"Nomor NPWP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                name={"no_npwp"}
                                onChange={(e) => this.handleChange(e, "number", "errNpwp")}
                                placeholder={"Contoh: 12.345.678.9-123.000"}
                                maxLength={"15"}
                                error={this.state.errNpwp}
                                readOnly={this.readOnly()}
                                value={this.state.no_npwp !== undefined ? this.state.no_npwp : "tidak dimasukkan no npwp"}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormLender
                                updateLender={this.state.update_lender}
                                forLabel={"id_jenis_badan_hukum"}
                                label={"Jenis Badan Usaha"}
                                name={"id_jenis_badan_hukum"}
                                // valueInput={}
                                value={this.state.id_jenis_badan_hukum}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Jenis Badan Usaha"}
                                data={this.props.jenis_buList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormLender
                                updateLender={this.state.update_lender}
                                forLabel={"id_bidang_pekerjaan"}
                                label={"Sektor Usaha"}
                                name={"id_bidang_pekerjaan"}
                                value={this.state.id_bidang_pekerjaan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Sektor Usaha"}
                                data={this.props.bidang_pekerjaanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormLender
                                updateLender={this.state.update_lender}
                                forLabel={"aset"}
                                label={"Total Aset"}
                                name={"aset"}
                                value={this.state.aset}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Total Aset"}
                                data={this.props.asetList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"sumber_dana"}
                                label={"Sumber Dana"}
                                onChange={(e) => this.handleChange(e, "string", "errSumber")}
                                type={"text"}
                                name={"sumber_dana"}
                                placeholder={"Contoh : Penghasilan"}
                                error={this.state.errSumber}
                                value={this.state.sumber_dana}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <FormSearch
                                updateLender={this.state.update_lender}
                                forLabel={"id_negara_domisili"}
                                label={"Negara Domisili"}
                                name={"negaraDomisili"}
                                value={this.state.id_negara_domisili}
                                onChange={this.handleSelectSearch.bind(null, "id_negara_domisili")}
                                data={this.props.negara_domisiliList}
                            />
                        </div>
                        <div className="col-12">
                            <InputIcon
                                forLabel={"alamat"}
                                label={"Alamat Perusahaan"}
                                icon={<Location />}
                                type={"text"}
                                name={"alamat"}
                                onChange={(e) => this.handleChange(e)}
                                placeholder={"Contoh: Jakarta"}
                                value={this.state.alamat}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormSearch
                                updateLender={this.state.update_lender}
                                forLabel={"id_provinsi"}
                                label={"Provinsi"}
                                name={"id_provinsi"}
                                value={this.state.id_provinsi}
                                onChange={this.handleSelectSearch.bind(null, "id_provinsi")}
                                data={this.props.provinsiList}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormSearch
                                updateLender={this.state.update_lender}
                                forLabel={"id_kota"}
                                label={"Kota/Kabupaten"}
                                name={"id_kota"}
                                value={this.state.id_kota}
                                onChange={this.handleSelectSearch.bind(null, "id_kota")}
                                data={this.state.kotaList}
                                isDisabled={!this.state.id_provinsi}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kecamatan"}
                                label={"Kecamatan"}
                                onChange={(e) => this.handleChange(e, "string", "errkecamatan")}
                                type={"text"}
                                name={"kecamatan"}
                                placeholder={"Contoh: Cengkareng Barat"}
                                error={this.state.errkecamatan}
                                value={this.state.kecamatan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kelurahan"}
                                label={"Kelurahan"}
                                onChange={(e) => this.handleChange(e, "string", "errKelurahan")}
                                type={"text"}
                                name={"kelurahan"}
                                placeholder={"Contoh: Cengkareng"}
                                error={this.state.errKelurahan}
                                value={this.state.kelurahan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kode_pos"}
                                label={"Kodepos"}
                                onChange={(e) => this.handleChange(e, "number", "errKodepos")}
                                type={"text"}
                                name={"kode_pos"}
                                placeholder={"Contoh : 12345"}
                                error={this.state.errKodepos}
                                maxLength={"5"}
                                readOnly={this.readOnly()}
                                value={this.state.kode_pos}
                            />
                        </div>
                    </div>
                    <hr />
                    <h5 className="font-weight-bold pb-4 text-primary">Informasi Perwakilan Perusahaan</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"nama_perwakilan"}
                                label={"Nama Perwakilan"}
                                icon={<i className="material-icons-outlined">account_circle</i>}
                                type={"text"}
                                name={"nama_perwakilan"}
                                onChange={(e) => this.handleChange(e, "string", "errNama")}
                                placeholder={"Contoh: Nama Perwakilan"}
                                error={this.state.errNama}
                                readOnly={this.readOnly()}
                                value={this.state.nama_perwakilan}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_handphone"}
                                label={"Nomor Handphone Perwakilan"}
                                icon={<i className="material-icons-outlined">smartphone</i>}
                                type={"text"}
                                name={"no_handphone"}
                                onChange={(e) => this.handleChange(e, "string", "errNumber")}
                                placeholder={"Contoh: 081xxxxxxx"}
                                error={this.state.errNumber}
                                readOnly={this.authReadonlyDigisign()}
                                value={this.state.no_handphone}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"tempat_lahir"}
                                label={"Tempat Lahir"}
                                icon={<Location />}
                                type={"text"}
                                name={"tempat_lahir"}
                                onChange={(e) => this.handleChange(e, "string", "errTempatLahir")}
                                placeholder={"Contoh: Jakarta"}
                                error={this.state.errTempatLahir}
                                readOnly={this.readOnly()}
                                value={this.state.tempat_lahir}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label htmlFor="tanggal_lahir">Tanggal Lahir Perwakilan</Label>
                                {this.authTglLahir()}
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormLender
                                updateLender={this.state.update_lender}
                                forLabel={"id_jenis_kelamin"}
                                label={"Jenis Kelamin"}
                                name={"id_jenis_kelamin"}
                                // valueInput={}
                                value={this.state.id_jenis_kelamin}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Jenis Kelamin"}
                                data={this.props.jeniskelaminList}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"jabatan_perwakilan"}
                                label={"Jabatan Perwakilan"}
                                onChange={(e) => this.handleChange(e, "string", "errJabatan")}
                                type={"text"}
                                name={"jabatan_perwakilan"}
                                placeholder={"Contoh: Finance"}
                                error={this.state.errJabatan}
                                value={this.state.jabatan_perwakilan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_ktp_perwakilan"}
                                label={"Nomor KTP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                name={"no_ktp_perwakilan"}
                                onChange={(e) => this.handleChange(e, "number", "errKtp")}
                                placeholder={"Contoh: 12-34-56-78-90-11-2222"}
                                maxLength={"16"}
                                error={this.state.errKtp}
                                readOnly={this.readOnly()}
                                value={this.state.no_ktp_perwakilan}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">{this.validFotoKTP()}</div>
                        <div className="col-md-6">{this.validFotoSelfie()}</div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <InputIcon
                                forLabel={"alamat_perwakilan"}
                                label={"Alamat Sesuai KTP"}
                                icon={<Location />}
                                type={"text"}
                                name={"alamat_perwakilan"}
                                onChange={(e) => this.handleChange(e)}
                                placeholder={"Contoh: Jakarta"}
                                value={this.state.alamat_perwakilan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormSearch
                                updateLender={this.state.update_lender}
                                forLabel={"provinsi_perwakilan"}
                                label={"Provinsi"}
                                name={"provinsi_perwakilan"}
                                value={this.state.provinsi_perwakilan}
                                onChange={this.handleSelectSearch.bind(null, "provinsi_perwakilan")}
                                data={this.props.provinsiList}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormSearch
                                updateLender={this.state.update_lender}
                                forLabel={"kota_perwakilan"}
                                label={"Kota/Kabupaten"}
                                name={"kota_perwakilan"}
                                value={this.state.kota_perwakilan}
                                onChange={this.handleSelectSearch.bind(null, "kota_perwakilan")}
                                data={this.state.kotaListDomisili}
                                isDisabled={!this.state.provinsi_perwakilan}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kelurahan"}
                                label={"Kelurahan"}
                                onChange={(e) => this.handleChange(e, "string", "errKelurahan")}
                                type={"text"}
                                name={"kelurahan_perwakilan"}
                                placeholder={"Contoh: Cengkareng"}
                                error={this.state.errKelurahan}
                                value={this.state.kelurahan_perwakilan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kecamatan"}
                                label={"Kecamatan"}
                                onChange={(e) => this.handleChange(e, "string", "errkecamatan")}
                                type={"text"}
                                name={"kecamatan_perwakilan"}
                                placeholder={"Contoh: Cengkareng Barat"}
                                error={this.state.errkecamatan}
                                value={this.state.kecamatan_perwakilan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kode_pos"}
                                label={"Kodepos"}
                                onChange={(e) => this.handleChange(e, "number", "errKodepos")}
                                type={"text"}
                                name={"kode_pos_perwakilan"}
                                placeholder={"Contoh : 12345"}
                                error={this.state.errKodepos}
                                maxLength={"5"}
                                readOnly={this.readOnly()}
                                value={this.state.kode_pos_perwakilan}
                            />
                        </div>
                    </div>
                    {this.confirmDataValid()}
                </Form>
            </div>
        );
    };
    render() {
        return (
            <div>
                {this.props.lender.isLoadingLender ? <LoadingBadanHukum /> : this.form()}

                {/* Modal show ktp */}
                <ModalFoto isOpen={this.state.modalktp} toggle={this.togglemodalktp.bind(this)} src={this.state.foto_ktp} title={"Foto KTP"} />

                {/* Modal show selfie */}
                <ModalFoto
                    isOpen={this.state.modalselfie}
                    toggle={this.togglemodalselfie.bind(this)}
                    src={this.state.foto_selfie}
                    title={"Foto Selfie"}
                />

                <OpenCamera
                    modalselfieWebcam={this.state.modalselfieWebcam}
                    toggle={this.toggleOpenCamera.bind(this)}
                    dataUri={this.state.foto_selfie}
                    handle={(e) => this.handleTakePhotoAnimationDone(e)}
                    fotoLagi={this.fotoLagi}
                    successFoto={this.successFoto}
                    camera={this.state.camera}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lender: state.lender,
        master: state.master
    };
};
export default connect(mapStateToProps)(DatadiriEntity);
