import React, { Component } from "react";
import { PrimaryRekening } from "../../../redux/action/User";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TarikSaldo from "./tariksaldo/TarikSaldo";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import ShowElement from "../../../library/components/ShowElement";
import { ShowElementContext } from "../../../context/ShowElementContextProvider";

class DompetInfo extends Component {
    static contextType = ShowElementContext;
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            modaltarik: false,
            setModal: false,
            loading: true,
            saldo: "",
            nama_pengguna: "",
            nama_bank: "",
            nomor_akun_bank: "",
            nama_pemilik_rekening: "",
            primary_bank: ""
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(PrimaryRekening());
        this.setState({
            primary_bank: this.props.user.bankList
        });

        if (this.state.primary_bank !== undefined) {
            this.setState({
                nama_bank: this.props.user.bankList.nama_bank,
                nomor_akun_bank: this.props.user.bankList.nomor_akun_bank,
                nama_pemilik_rekening: this.props.user.bankList.nama_pemilik_rekening
            });
        }
    }

    togglemodaltarik() {
        this.setState({
            modaltarik: !this.state.modaltarik,
            setModal: !this.state.setModal
        });
        setTimeout(
            () =>
                this.setState({
                    modaltarik: false,
                    setModal: false
                }),
            60000
        );
    }

    Dompet = () => {
        const { isShowSaldo, toggleIsShowSaldo } = this.context;
        return (
            <div className="col-12 col-md-6 mb-3 mb-md-0">
                <div className="card border-0 shadow card-statistic">
                    <div className="card-body">
                        <div className="d-flex mb-3">
                            <i className="text-primary material-icons mr-3">account_balance_wallet</i>
                            <div className="w-100">
                                <p className="text-secondary mb-0">Total Saldo</p>
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    {isShowSaldo ? (
                                        <NumberFormat
                                            value={this.props.saldo}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={"Rp "}
                                            decimalScale={0}
                                            renderText={(value) => <h4 className="font-weight-semi-bold mb-0 mr-2">{value}</h4>}
                                        />
                                    ) : (
                                        <h4 className="font-weight-semi-bold mb-0 mr-2">*****</h4>
                                    )}
                                    <div>
                                        <ShowElement isShow={isShowSaldo} toggleIsShow={toggleIsShowSaldo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i className="text-alert float-start">
                            *Apabila sampai dengan T + 2 tidak dilakukan penarikan, maka akan otomatis ditransfer pada rekening yang terdaftar
                        </i>
                        <div className="row mt-2">
                            <div className="col-6 col-sm-6">
                                <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#withdrawModal"
                                    className="btn btn-outline-primary btn-long"
                                    onClick={this.togglemodaltarik.bind(this)}>
                                    Tarik Saldo
                                </button>
                            </div>
                            <div className="col-6 col-sm-6">
                                {localStorage.masuk === "1" ? (
                                    <Link
                                        to="/lender/topup"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#topupModal-1"
                                        className="btn btn-outline-primary btn-long">
                                        Top Up Saldo
                                    </Link>
                                ) : (
                                    <Link
                                        to="/borrower/topup"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#topupModal-1"
                                        className="btn btn-outline-primary btn-long">
                                        Top Up Saldo
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    BankPrimary = () => {
        // console.log(this.props.user.isLoadingPrimary)
        if (this.state.primary_bank !== undefined) {
            return (
                <div className="col-12 col-md-6">
                    <div className="card border-0 shadow card-statistic">
                        <div className="card-body">
                            <div className="d-flex">
                                <i className="text-primary material-icons mr-3">account_balance</i>
                                <div className="flex-fill">
                                    <p className="text-secondary mb-2">Rekening Bank Utama</p>
                                    <div className="row">
                                        <div className="col-4 col-md-4 d-flex align-items-center">
                                            {this.props.user.isLoadingPrimary === true ? (
                                                <Skeleton height={60} width={110} />
                                            ) : (
                                                <img
                                                    className="img-fluid"
                                                    src={process.env.PUBLIC_URL + `/images/bank/${this.state.nama_bank}.png`}
                                                    alt="bank-img"
                                                />
                                            )}
                                        </div>
                                        <div className="col-8 col-md-8">
                                            {this.props.user.isLoadingPrimary === true ? (
                                                <div>
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p className="mb-1">{this.state.nama_bank}</p>
                                                    <p className="mb-1 fs-1 font-weight-semi-bold">
                                                        {this.state.nama_pemilik_rekening
                                                            ? this.state.nama_pemilik_rekening
                                                            : localStorage.nama_pengguna}
                                                    </p>
                                                    <p className="mb-0">{this.state.nomor_akun_bank}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12 col-md-6">
                    <div className="card border-0 shadow card-statistic">
                        <div className="card-body">
                            <div className="d-flex">
                                <i className="text-primary material-icons mr-3">account_balance</i>
                                <div className="flex-fill">
                                    <p className="text-secondary mb-2">Rekening Bank Utama</p>
                                    <div className="row">
                                        <div className="col-8 col-md-8">
                                            <p className="mb-1">Rekening Tidak Ada</p>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    render() {
        // console.log(this.props.saldo)
        return (
            <div className="row mb-4">
                {this.Dompet()}
                {this.BankPrimary()}

                <TarikSaldo
                    isOpen={this.state.modaltarik}
                    toggle={this.togglemodaltarik.bind(this)}
                    users={this.state.nama_pemilik_rekening}
                    nama_pengguna={this.props.nama_pengguna}
                    saldo={this.props.saldo}
                    nama_bank={this.state.nama_bank}
                    no_rek={this.state.nomor_akun_bank}
                    primary_bank={this.state.primary_bank}
                    userNameDipay={this.props.userNameDipay}
                    userTlpDipay={this.props.userTlpDipay}
                    loadingUserDipay={this.props.loadingUserDipay}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(DompetInfo);
