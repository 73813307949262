import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { BorrowerId, UpdateDigisignBorrower } from "../../../../redux/action/Borrower";
import { InputIcon, Inputs, ShowFoto, ModalFoto, OpenCamera, CorrectFotoSelfie } from "../../../../library/components/Components";
import { CheckDigisignData, imgToFile } from "../../../../library/helper/helper";
import { validate } from "../../../../library/validation/validate";
import Location from "../../../assets/svg/Location"; //icon svg
import { LoadingBadanHukum } from "../../../assets/loading data/LoadingBorrower";

class DatadiriEntity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_borrower: "",
            jenis_pengguna: "",
            no_npwp: "",
            id_jenis_badan_hukum: "",
            alamat: "",
            id_kota: "",
            id_provinsi: "",
            kode_pos: "",
            id_bidang_pekerjaan: "",
            id_pekerjaan_online: "",
            aset: "",
            total_asset: "",
            nama_perwakilan: "",
            no_ktp_perwakilan: "",
            foto_selfie: "",
            foto_ktp: "",
            no_handphone: "",
            file: "",
            modal: false,
            modalktp: false,
            modalselfie: false,
            setModal: false,
            popoverOpen: false,
            checked: false,
            disabled: true,
            id_sektor: "",
            deskripsi_usaha: "",
            kecamatan: "",
            kelurahan: "",
            jabatan_perwakilan: "",
            alamat_perwakilan: "",
            provinsi_perwakilan: "",
            kota_perwakilan: "",
            kelurahan_perwakilan: "",
            kecamatan_perwakilan: "",
            kode_pos_perwakilan: "",
            tgl_lahir: "",
            id_jenis_kelamin: "",
            email: "",
            loading: true,
            camera: true,
            fotoDone: false,
            modalselfieWebcam: false,
            update_borrower: "",
            errEmail: "",
            errNumber: "",
            tempat_lahir: "",
            re_fotoselfie: ""
        };
        this.fotoLagi = this.fotoLagi.bind(this);
        this.successFoto = this.successFoto.bind(this);
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    togglemodalktp() {
        this.setState({
            modalktp: !this.state.modalktp,
            setModal: !this.state.setModal
        });
    }

    togglemodalselfie() {
        this.setState({
            modalselfie: !this.state.modalselfie,
            setModal: !this.state.setModal
        });
    }

    //button check valid
    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };

    confirmDataValid() {
        if (this.state.update_borrower === true) {
            return (
                <div>
                    <div className="mt-4 mb-5">
                        <div className="custom-control custom-checkbox filter-input">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={this.handleCheck}
                                    defaultChecked={this.state.checked || ""}
                                    id="agreement"
                                    name="agreement"
                                />
                                Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, dan saya telah melakukan pengecekan atas
                                kebenaran data-data tersebut.
                            </Label>
                        </div>
                    </div>
                    <div className="text-center">
                        {this.props.borrower.isLoadingUpdateDigisign ? (
                            <Button disabled color="primary" onClick={this.handleSaveBorrower} className="btn btn-lg col-12 col-md-6">
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button
                                disabled={this.state.disabled}
                                color="primary"
                                onClick={this.handleSaveBorrower}
                                className="btn btn-lg col-12 col-md-6">
                                Simpan
                            </Button>
                        )}
                    </div>
                </div>
            );
        }
    }

    //foto camera
    toggleOpenCamera() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }

    handleTakePhotoAnimationDone(dataUri) {
        this.setState({
            re_fotoselfie: dataUri,
            camera: false
        });
    }
    fotoLagi() {
        this.setState({
            camera: !this.state.camera
        });
    }
    successFoto = () => {
        this.setState({
            modalselfieWebcam: false,
            setModal: false,
            fotoDone: true
        });
    };
    //

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(BorrowerId(this.props.id));
        this.setState({
            tgl_lahir: new Date(this.props.borrower.borrowerDetail.tgl_lahir).toLocaleDateString("IN"),
            no_ktp_perwakilan: this.props.borrower.borrowerDetail.no_ktp_perwakilan,
            no_npwp: this.props.borrower.borrowerDetail.no_npwp,
            alamat: this.props.borrower.borrowerDetail.alamat,
            nama_perwakilan: this.props.borrower.borrowerDetail.nama_perwakilan,
            kelurahan: this.props.borrower.borrowerDetail.kelurahan,
            kecamatan: this.props.borrower.borrowerDetail.kecamatan,
            kode_pos: this.props.borrower.borrowerDetail.kode_pos,
            foto_ktp: this.props.borrower.borrowerDetail.foto_ktp,
            foto_selfie: this.props.borrower.borrowerDetail.foto_selfie,
            total_asset: this.props.borrower.borrowerDetail.total_asset,
            deskripsi_usaha: this.props.borrower.borrowerDetail.deskripsi_usaha,
            jabatan_perwakilan: this.props.borrower.borrowerDetail.jabatan_perwakilan,
            alamat_perwakilan: this.props.borrower.borrowerDetail.alamat_perwakilan,
            kecamatan_perwakilan: this.props.borrower.borrowerDetail.kecamatan_perwakilan,
            kelurahan_perwakilan: this.props.borrower.borrowerDetail.kelurahan_perwakilan,
            kode_pos_perwakilan: this.props.borrower.borrowerDetail.kode_pos_perwakilan,
            nama_borrower: this.props.borrower.borrowerDetail.nama_borrower,
            update_borrower: this.props.borrower.borrowerDetail.update_borrower,
            tempat_lahir: this.props.borrower.borrowerDetail.tempat_lahir,
            no_handphone: this.props.borrower.borrowerDetail.pengguna.no_handphone,
            email: this.props.borrower.borrowerDetail.pengguna.email,

            kota_perwakilan: this.props.borrower.borrowerDetail.ojkJson.kotaPerwakilan.keterangan,
            provinsi_perwakilan: this.props.borrower.borrowerDetail.ojkJson.provinsiPerwakilan.keterangan,
            id_sektor: this.props.borrower.borrowerDetail.ojkJson.sektor.keterangan,
            id_jenis_badan_hukum: this.props.borrower.borrowerDetail.ojkJson.jenisBadanHukum.keterangan,
            id_jenis_kelamin: this.props.borrower.borrowerDetail.ojkJson.jenisKelamin.keterangan,
            id_provinsi: this.props.borrower.borrowerDetail.ojkJson.provinsi.keterangan,
            id_kota: this.props.borrower.borrowerDetail.ojkJson.kota.keterangan,
            id_bidang_pekerjaan: this.props.borrower.borrowerDetail.ojkJson.bidangPekerjaan.keterangan,
            id_pekerjaan_online: this.props.borrower.borrowerDetail.ojkJson.pekerjaanOnline.keterangan,
            aset: this.props.borrower.borrowerDetail.ojkJson.aset.keterangan
        });
        // console.log(this.props.borrower.borrowerDetail.ojkJson)
        // console.log(this.props.borrower.borrowerDetail)
        CheckDigisignData(this.state.update_borrower, true); //notif ubah data
    }

    //function save data yg dirubah
    handleSaveBorrower = async () => {
        //email , no hp milik user
        const { email, no_handphone, foto_selfie } = this.state;

        const formData = new FormData();
        formData.append("email", email);
        formData.append("no_handphone", no_handphone);
        formData.append("image", imgToFile(foto_selfie));

        await this.props
            .dispatch(UpdateDigisignBorrower(formData))
            .then(() => {
                return Swal.fire({
                    title: "Update Data",
                    text: "Data anda berhasil di rubah. Data anda akan di validasi kembali",
                    icon: "success",
                    button: "ok"
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                // console.log(err.response)
                Swal.fire({
                    title: "Gagal Update",
                    text: `${err.response.data.message}`,
                    icon: "warning",
                    button: "ok"
                });
            });
    };
    //yg diupdate email, nohp, foto selfie
    authUpdateBorrower = () => {
        if (this.state.update_borrower === false) {
            return true;
        } else {
            return false;
        }
    };

    form = () => {
        return (
            <div>
                <Form>
                    <h5 className="font-weight-bold pb-3 text-primary">Informasi Perusahaan</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"nama_borrower"}
                                label={"Nama Borrower"}
                                icon={<i className="material-icons-outlined">account_circle</i>}
                                type={"text"}
                                name={"nama_borrower"}
                                value={this.state.nama_borrower}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"email"}
                                label={"Email"}
                                icon={<i className="material-icons-outlined">mail</i>}
                                type={"email"}
                                name={"email"}
                                onChange={(e) => this.handleChange(e, "email", "errEmail")}
                                placeholder={"Contoh: example@email.com"}
                                value={this.state.email}
                                error={this.state.errEmail}
                                readOnly={this.authUpdateBorrower()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_npwp"}
                                label={"Nomor NPWP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                name={"no_npwp"}
                                placeholder={"Contoh: 12.345.678.9-123.000"}
                                value={this.state.no_npwp !== undefined ? this.state.no_npwp : "Tidak ada NPWP"}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_jenis_badan_hukum"}
                                label={"Jenis Badan Usaha"}
                                type={"text"}
                                name={"id_jenis_badan_hukum"}
                                value={this.state.id_jenis_badan_hukum}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_sektor"}
                                label={"Sektor Usaha"}
                                type={"text"}
                                name={"id_sektor"}
                                value={this.state.id_sektor}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_pekerjaan_online"}
                                label={"Pekerjaan Online"}
                                type={"text"}
                                name={"id_pekerjaan_online"}
                                value={this.state.id_pekerjaan_online}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"aset"}
                                label={"Pendapatan per bulan"}
                                type={"text"}
                                name={"aset"}
                                value={this.state.aset}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"total_asset"}
                                label={"Total Aset"}
                                icon={<span className="font-weight-semi-bold">Rp</span>}
                                type={"text"}
                                name={"total_asset"}
                                value={this.state.total_asset.toLocaleString("IN")}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <FormGroup>
                                <Label for="exampleText">Deskripsi Usaha</Label>
                                <Input value={this.state.deskripsi_usaha} type="textarea" rows="8" name="deskripsi_usaha" id="exampleText" readOnly />
                            </FormGroup>
                        </div>
                    </div>
                    <hr />
                    <h5 className="font-weight-bold pb-3 text-primary">Alamat Perusahaan</h5>
                    <div className="row">
                        <div className="col-12">
                            <InputIcon
                                forLabel={"alamat"}
                                label={"Alamat Perusahaan"}
                                icon={<Location />}
                                type={"text"}
                                name={"alamat"}
                                value={this.state.alamat}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_provinsi"}
                                label={"Provinsi"}
                                type={"text"}
                                name={"id_provinsi"}
                                value={this.state.id_provinsi}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_kota"}
                                label={"Kota / Kabupaten"}
                                type={"text"}
                                name={"id_kota"}
                                value={this.state.id_kota}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kecamatan"}
                                label={"Kecamatan"}
                                type={"text"}
                                name={"kecamatan"}
                                value={this.state.kecamatan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kelurahan"}
                                label={"Kelurahan"}
                                type={"text"}
                                name={"kelurahan"}
                                value={this.state.kelurahan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kode_pos"}
                                label={"Kode Pos"}
                                type={"text"}
                                name={"kode_pos"}
                                value={this.state.kode_pos}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <hr />
                    <h5 className="font-weight-bold pb-3 text-primary">Informasi Perwakilan (PIC) Perusahaan</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"nama_perwakilan"}
                                label={"Nama Perwakilan"}
                                icon={<i className="material-icons-outlined">account_circle</i>}
                                type={"text"}
                                name={"nama_perwakilan"}
                                value={this.state.nama_perwakilan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_handphone"}
                                label={"Nomor Handphone"}
                                icon={<i className="material-icons-outlined">smartphone</i>}
                                type={"text"}
                                name={"no_handphone"}
                                placeholder={"Contoh: 081xxxxxxxxxx"}
                                value={this.state.no_handphone}
                                readOnly={this.authUpdateBorrower()}
                                onChange={(e) => this.handleChange(e, "number", "errNumber")}
                                error={this.state.errNumber}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"jabatan_perwakilan"}
                                label={"Jabatan Perwakilan"}
                                type={"text"}
                                name={"jabatan_perwakilan"}
                                value={this.state.jabatan_perwakilan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_bidang_pekerjaan"}
                                label={"Bidang Pekerjaan"}
                                type={"text"}
                                name={"id_bidang_pekerjaan"}
                                value={this.state.id_bidang_pekerjaan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"tempat_lahir"}
                                label={"Tempat Lahir"}
                                icon={<Location />}
                                type={"text"}
                                name={"tempat_lahir"}
                                value={this.state.tempat_lahir}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"tgl_lahir"}
                                label={"Tanggal Lahir"}
                                icon={<i className="material-icons-outlined">calendar_today</i>}
                                type={"text"}
                                name={"tgl_lahir"}
                                value={this.state.tgl_lahir}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_jenis_kelamin"}
                                label={"Jenis Kelamin"}
                                type={"text"}
                                name={"id_jenis_kelamin"}
                                value={this.state.id_jenis_kelamin}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_ktp_perwakilan"}
                                label={"Nomor KTP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                name={"no_ktp_perwakilan"}
                                value={this.state.no_ktp_perwakilan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <ShowFoto label={"Foto KTP"} pc={"Sudah Foto KTP"} onClick={this.togglemodalktp.bind(this)} />
                        </div>
                        <div className="col-md-6">
                            <CorrectFotoSelfie
                                data={this.state.update_borrower}
                                onChangeInput={this.toggleOpenCamera.bind(this)}
                                fotoDone={this.state.fotoDone}
                                onChangeShow={this.togglemodalselfie.bind(this)}
                            />
                        </div>
                    </div>
                    <hr />
                    <h5 className="font-weight-bold pb-3 text-primary">Alamat Perwakilan</h5>
                    <div className="row">
                        <div className="col-12">
                            <InputIcon
                                forLabel={"alamat"}
                                label={"Alamat Perwakilan"}
                                icon={<Location />}
                                type={"text"}
                                name={"alamat"}
                                value={this.state.alamat_perwakilan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"provinsi_perwakilan"}
                                label={"Provinsi"}
                                type={"text"}
                                name={"provinsi_perwakilan"}
                                value={this.state.provinsi_perwakilan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kota_perwakilan"}
                                label={"Kota / Kabupaten"}
                                type={"text"}
                                name={"kota_perwakilan"}
                                value={this.state.kota_perwakilan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kelurahan_perwakilan"}
                                label={"Kelurahan"}
                                type={"text"}
                                name={"kelurahan_perwakilan"}
                                value={this.state.kelurahan_perwakilan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kecamatan_perwakilan"}
                                label={"Kecamatan"}
                                type={"text"}
                                name={"kecamatan_perwakilan"}
                                value={this.state.kecamatan_perwakilan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kode_pos_perwakilan"}
                                label={"Kode Pos"}
                                type={"text"}
                                name={"kode_pos_perwakilan"}
                                value={this.state.kode_pos_perwakilan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <hr />
                    {this.confirmDataValid()}
                </Form>
            </div>
        );
    };
    render() {
        return (
            <div>
                {this.props.borrower.isLoadingGetID === true ? <LoadingBadanHukum /> : this.form()}

                {/* Modal show ktp */}
                <ModalFoto isOpen={this.state.modalktp} toggle={this.togglemodalktp.bind(this)} src={this.state.foto_ktp} title={"Foto KTP"} />

                {/* Modal show selfie */}
                <ModalFoto
                    isOpen={this.state.modalselfie}
                    toggle={this.togglemodalselfie.bind(this)}
                    src={this.state.foto_selfie}
                    title={"Foto Selfie"}
                />

                {/* Modal Selfie Webcam */}
                <OpenCamera
                    modalselfieWebcam={this.state.modalselfieWebcam}
                    toggle={this.toggleOpenCamera.bind(this)}
                    dataUri={this.state.re_fotoselfie}
                    handle={(e) => this.handleTakePhotoAnimationDone(e)}
                    fotoLagi={this.fotoLagi}
                    successFoto={this.successFoto}
                    camera={this.state.camera}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        borrower: state.borrower
    };
};
export default connect(mapStateToProps)(DatadiriEntity);
