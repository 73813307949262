import React, { Component } from "react";
import { LendApp } from "../../../redux/action/Lender";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import NoData from "../../assets/no data/NoData";
import { namaSektor, colorStatistikSektor } from "../../../library/helper/helper";

class StatistikLend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartDataSektor: {},
      chartDataGrade: {},
      info: []
    };
  }
  async componentDidMount() {
    await this.props.dispatch(LendApp());
    this.setState({
      info: this.props.lender.lenderLend
    });
    this.getChartSektor();
    this.getChartGrade();
    // console.log(this.doughnuts.chartInstance.legend.legendItems)
  }
  //grade
  getChartGrade() {
    let arr = [];
    this.state.info.map((val) => {
      if (val.id_pinjaman.status_proyek !== "Batal") {
        arr.push(val.id_pinjaman && val.id_pinjaman.grade_proyek);
      }
    });
    const noDuplicateName1 = arr.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) === index;
    });

    const result = {};
    for (let i = 0; i < arr.length; i++) {
      result[arr[i]] = (result[arr[i]] || 0) + 1;
    }
    //label grade
    const labelGrade = noDuplicateName1.map((val) => {
      if (val === "A") {
        return "A";
      } else if (val === "B") {
        return "B";
      } else if (val === "C") {
        return "C";
      } else if (val === "D") {
        return "D";
      } else if (val === "E") {
        return "E";
      } else if (val === undefined) {
        return "Lain lain";
      }
      // console.log(!val ? 'gabisa': 'bisa')
      // console.log(noDuplicateName1.length !== 0 ? 'bisa': 'gabisa')
    });
    const valueGrade = noDuplicateName1.map((val) => {
      if (val === "A") {
        return result.A;
      } else if (val === "B") {
        return result.B;
      } else if (val === "C") {
        return result.C;
      } else if (val === "D") {
        return result.D;
      } else if (val === "E") {
        return result.E;
      } else if (val === undefined) {
        return result.undefined;
      }
    });

    const colorGrade = noDuplicateName1.map((val) => {
      if (val === "A") {
        return "#88a825";
      } else if (val === "B") {
        return "#1a355b";
      } else if (val === "C") {
        return "#911146";
      } else if (val === "D") {
        return "#cf4a30";
      } else if (val === "E") {
        return "#ed8c2b";
      } else {
        return "rgba(181, 181, 181, 1)";
      }
    });

    this.setState({
      chartDataGrade: {
        labels: labelGrade,
        // labels:['A', 'B', 'C', 'D', 'E'],
        datasets: [
          {
            data: valueGrade,
            backgroundColor: colorGrade
            // data:[result.A, result.B, result.C, result.D, result.E],
            // backgroundColor: [
            //     'rgba(23, 255, 54, 1)',
            //     'rgba(3, 202, 30, 1)',
            //     'rgba(255, 90, 1, 1)',
            //     'rgba(255, 59, 48, 1)',
            //     'rgba(186, 10, 0, 1)'
            // ],
          }
        ]
      }
    });
  }

  legendGrade = () => {
    let arr = [];
    this.state.info.map((val) => {
      if (val.id_pinjaman.status_proyek !== "Batal") {
        arr.push(val.id_pinjaman && val.id_pinjaman.grade_proyek);
      }
    });
    const noDuplicateName1 = arr.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) === index;
    });

    const result = {};
    for (let i = 0; i < arr.length; i++) {
      result[arr[i]] = (result[arr[i]] || 0) + 1;
    }
    //label grade
    const label = (val) => {
      if (val === "A") {
        return "A";
      } else if (val === "B") {
        return "B";
      } else if (val === "C") {
        return "C";
      } else if (val === "D") {
        return "D";
      } else if (val === "E") {
        return "E";
      } else if (val === undefined) {
        return "Lain lain";
      }
    };
    const colorGrade = (val) => {
      if (val === "A") {
        return "#88a825";
      } else if (val === "B") {
        return "#1a355b";
      } else if (val === "C") {
        return "#911146";
      } else if (val === "D") {
        return "#cf4a30";
      } else if (val === "E") {
        return "#ed8c2b";
      } else {
        return "rgba(181, 181, 181, 1)";
      }
    };
    const hasil = noDuplicateName1.sort().map((val, index) => {
      return (
        <li
          key={index}
          style={{
            listStyle: "none",
            textAlign: "left",
            display: "flex",
            flexDirection: "row"
          }}>
          <div
            style={{
              marginRight: "8px",
              width: "15px",
              height: "15px",
              backgroundColor: colorGrade(val),
              borderRadius: "50px",
              position: "absolute"
            }}
          />
          <div className="pl-4">Grade {label(val)}</div>
        </li>
      );
    });
    return hasil;
  };

  //sektor
  getChartSektor() {
    // get name label no duplicate
    let arr = [];
    this.state.info.map((val) => {
      if (val.id_pinjaman.status_proyek !== "Batal") {
        arr.push(val.id_pinjaman && val.id_pinjaman.penggunaan_pinjaman);
      }
    });
    const noDuplicateName = arr.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) === index;
    });

    const result = {};
    for (let i = 0; i < arr.length; i++) {
      result[arr[i]] = (result[arr[i]] || 0) + 1;
    }
    //definition code of sektor usaha
    const labelSektor = noDuplicateName.map((val) => {
      // console.log(val)
      return namaSektor(val);
    });

    const valueSektor = noDuplicateName.map((val) => {
      if (val === "e0") {
        return result.e0;
      } else if (val === "e2") {
        return result.e2;
      } else if (val === "e5") {
        return result.e5;
      } else if (val === "e6") {
        return result.e6;
      } else if (val === "e12") {
        return result.e12;
      } else if (val === "e36") {
        return result.e36;
      } else if (val === "e39") {
        return result.e39;
      } else if (val === "e40") {
        return result.e40;
      } else if (val === "e46") {
        return result.e46;
      } else if (val === "e47") {
        return result.e47;
      } else if (val === "e52") {
        return result.e52;
      } else if (val === "e56") {
        return result.e56;
      } else if (val === "e62") {
        return result.e62;
      } else if (val === "e63") {
        return result.e63;
      } else if (val === "e64") {
        return result.e64;
      } else if (val === "e67") {
        return result.e67;
      } else if (val === "e70") {
        return result.e70;
      } else if (val === "e71") {
        return result.e71;
      } else if (val === "e72") {
        return result.e72;
      } else if (val === "e73") {
        return result.e73;
      } else if (val === "e74") {
        return result.e74;
      } else if (val === undefined) {
        return result.undefined;
      }
    });

    const warnaSektor = noDuplicateName.map((val) => {
      return colorStatistikSektor(val);
    });

    this.setState({
      chartDataSektor: {
        labels: labelSektor,
        datasets: [
          {
            data: valueSektor,
            backgroundColor: warnaSektor
          }
        ]
      }
    });
  }

  legendSektor = () => {
    const test = this.doughnut;
    // console.log(test && test.chartInstance.legend.legendItems)
    const hasil =
      test &&
      test.chartInstance.legend.legendItems.map((val, index) => {
        return (
          <li
            key={index}
            style={{
              listStyle: "none",
              textAlign: "left",
              display: "flex",
              flexDirection: "row"
            }}>
            <div
              style={{
                marginRight: "8px",
                width: "15px",
                height: "15px",
                backgroundColor: val.fillStyle,
                borderRadius: "50px",
                position: "absolute"
              }}
            />
            <div className="pl-4">{val.text}</div>
          </li>
        );
      });
    return hasil;
  };

  render() {
    return (
      <div>
        {/* //     {test && test.chartInstance.legend.legendItems.length ===0 ? 'Loading': */}
        {this.state.info.length !== 0 ? (
          <div className="chart">
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="sector-title text-center pt-5">Resiko Pendanaan</p>
                <Doughnut
                  ref={(ref) => (this.doughnut = ref)}
                  data={this.state.chartDataGrade}
                  options={{
                    events: false,
                    legend: {
                      display: false
                    },
                    animation: {
                      duration: 500,
                      easing: "easeOutQuart",
                      onComplete: function () {
                        var ctx = this.chart.ctx;
                        //   ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = "center";
                        ctx.textBaseline = "bottom";
                        ctx.font = "15px sans-serif";

                        this.data.datasets.forEach(function (dataset) {
                          for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                              total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                              mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                              start_angle = model.startAngle,
                              end_angle = model.endAngle,
                              mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = "#fff";
                            if (i === 3) {
                              // Darker text color for lighter background
                              ctx.fillStyle = "#444";
                            }
                            var percent = String(Math.round((dataset.data[i] / total) * 100)) + "%";
                            ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                            // Display percent in another line, line break doesn't work for fillText
                            ctx.fillText(percent, model.x + x, model.y + y + 15);
                          }
                        });
                      }
                    }
                  }}
                />
                <ul className="pt-3">{this.legendGrade()}</ul>
              </div>
              <div className="col-12 col-md-6">
                <p className="sector-title text-center pt-5">Sektor</p>
                <Doughnut
                  ref={(ref) => (this.doughnut = ref)}
                  data={this.state.chartDataSektor}
                  options={{
                    events: false,
                    legend: {
                      display: false
                    },
                    animation: {
                      duration: 500,
                      easing: "easeOutQuart",
                      onComplete: function () {
                        var ctx = this.chart.ctx;
                        //   ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = "center";
                        ctx.textBaseline = "bottom";
                        ctx.font = "15px sans-serif";

                        this.data.datasets.forEach(function (dataset) {
                          for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                              total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                              mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                              start_angle = model.startAngle,
                              end_angle = model.endAngle,
                              mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = "#fff";
                            if (i === 3) {
                              // Darker text color for lighter background
                              ctx.fillStyle = "#444";
                            }
                            var percent = String(Math.round((dataset.data[i] / total) * 100)) + "%";
                            ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                            // Display percent in another line, line break doesn't work for fillText
                            ctx.fillText(percent, model.x + x, model.y + y + 15);
                          }
                        });
                      }
                    }
                  }}
                />
                <ul className="pt-3">{this.legendSektor()}</ul>
              </div>
            </div>
            <div className="row"></div>
          </div>
        ) : (
          <NoData symbol={<i className="material-icons">assessment</i>} keterangan={"Anda belum mendanai proyek"} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lender: state.lender
  };
};
export default connect(mapStateToProps)(StatistikLend);
