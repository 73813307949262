import React, { Component } from "react";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { sukuBunga } from "../../../library/helper/helper";
import moment from "moment";
import "moment/locale/id";

class COutstanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            currentPageAktif: 1,
            todosPerPageAktif: 15,
            filter: ""
        };
        this.handleClickAktif = this.handleClickAktif.bind(this);
    }

    handleClickAktif(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPageAktif: pageNumber
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };

    Outstanding = (currentPageAktif, todosPerPageAktif, lengthAktif) => {
        if (this.props.data === undefined) {
            return <div></div>;
        } else {
            const indexOfLastTodo = currentPageAktif * todosPerPageAktif;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPageAktif;
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }

            const currentTodos = this.props.data
                .filter((item) =>
                    this.state.filter === ""
                        ? (item.id_pinjaman && item.id_pinjaman.status_proyek === "Pembayaran cicilan") ||
                          (item.id_pinjaman && item.id_pinjaman.status_proyek === "Macet")
                        : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase()) &&
                          ((item.id_pinjaman && item.id_pinjaman.status_proyek === "Pembayaran cicilan") ||
                              (item.id_pinjaman && item.id_pinjaman.status_proyek === "Macet"))
                )
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);

            if (currentTodos !== 0 && lengthAktif !== 0) {
                const hasil = currentTodos.map((val) => {
                    const rincianPinjaman = val.id_pinjaman.rincian_pinjaman;
                    const jatuhTempo = () => {
                        if (rincianPinjaman === undefined) {
                            return "";
                        } else {
                            const jatuhTempo = rincianPinjaman[rincianPinjaman.length - 1].jatuh_tempo;
                            const statusBayar = rincianPinjaman[rincianPinjaman.length - 1].status_bayar;
                            const date1 = new Date(new Date().setHours(7, 0, 0, 0));
                            const date2 = new Date(jatuhTempo);
                            const diffTime = Math.abs(date2 - date1);
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                            if (date1 > date2 && statusBayar === false) {
                                return <p className="mb-0"> {"- " + diffDays + " hari"}</p>;
                            } else if (statusBayar === true) {
                                return <p className="mb-0"> {new Date(jatuhTempo).toLocaleDateString("IN")}</p>;
                            } else {
                                return <p className="mb-0"> {diffDays + " hari lagi"}</p>;
                            }
                        }
                    };
                    // let sukuBunga =
                    //   val.id_pinjaman.suku_bunga_pinjaman - val.id_pinjaman.spread_bunga;
                    return (
                        <div key={val._id}>
                            <div className="transaction p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-12 mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                        <p className="mb-0" style={{ display: "flex" }}>
                                                            {val.id_pinjaman.nama_pinjaman}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-1 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                                        <p className="mb-0">
                                                            {sukuBunga(
                                                                val.id_pinjaman.tipe_pinjaman,
                                                                val.id_pinjaman.jenis_pinjaman,
                                                                val.id_pinjaman.suku_bunga_pinjaman,
                                                                val.id_pinjaman.suku_bunga_konsumtif,
                                                                val.id_pinjaman.suku_bunga_micro,
                                                                val.id_pinjaman.spread_bunga,
                                                                true
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                                        <p className="mb-0">Rp {val.total_lend.toLocaleString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Tanggal Mulai Proyek</p>
                                                        <p className="mb-0">
                                                            {moment(new Date(val.id_pinjaman.tgl_perjanjian_pinjaman)).locale("id").format("LL")}
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Jatuh Tempo</p>
                                                        <div key={rincianPinjaman[rincianPinjaman.length - 1]._id}>{jatuhTempo()}</div>
                                                    </div>
                                                    <div className="col-12 col-md-2 text-center">
                                                        <a
                                                            href={"/detail-porto/" + val.id_pinjaman._id}
                                                            className="btn btn-outline-primary btn-block">
                                                            <span>Detail</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
                return hasil;
            } else {
                return (
                    <div className="transactions-wrapper m-n3">
                        <NoData
                            symbol={<i className="material-icons-outlined">assignment</i>}
                            keterangan={this.props.data.length !== 0 ? "Proyek yg anda danai dalam tahap menggalang dana" : "Belum ada Transaksi"}
                        />
                        <div className="text-center">
                            {this.props.data.length !== 0 ? (
                                ""
                            ) : (
                                <a href="/proyek" className="btn btn-outline-primary btn-mulai-dana">
                                    Mulai Danai Sekarang
                                </a>
                            )}
                        </div>
                    </div>
                );
            }
        }
    };
    render() {
        const { currentPageAktif, todosPerPageAktif } = this.state;
        const { filter } = this.state;

        const lengthAktif = this.props.data.filter((item) =>
            this.state.filter === ""
                ? (item.id_pinjaman && item.id_pinjaman.status_proyek === "Pembayaran cicilan") ||
                  (item.id_pinjaman && item.id_pinjaman.status_proyek === "Macet")
                : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase())
        ).length;

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between my-3">
                    {/* <div>
                    <label className="mb-0 mr-2 d-none d-md-inline-block" for="urutkan">Urutkan</label>
                    <select id="urutkan" className="custom-select w-auto mr-2">
                        <option value="">Suku Bunga</option>
                    </select>
                </div> */}
                    <div>
                        <form>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    placeholder="Cari Transaksi"
                                    value={filter}
                                    onChange={this.handleChangeSearch}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <i className="material-icons-outlined">search</i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {this.Outstanding(currentPageAktif, todosPerPageAktif, lengthAktif)}

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPageAktif}
                        itemsCountPerPage={this.state.todosPerPageAktif}
                        totalItemsCount={parseInt(lengthAktif)}
                        onChange={this.handleClickAktif.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default COutstanding;
